<template>
  <div>
    <v-banner
 sticky
 dark
 rounded
 color="teal lighten-3"
 >
 <span class="title"> Verify Account </span>
</v-banner>

<v-container>
  <v-row justify="center">
    <v-col cols="12" md="6" lg="6" xl="6" sm="12">   
   <v-card elevation="1" max-width="500">
     <v-progress-linear
                    :active="loading"
                    :indeterminate="loading"
                    absolute
                    top
                    color="blue accent-4"
                  ></v-progress-linear> 
     <v-card-title>
      Verify Your Email Address
     </v-card-title>
         <v-card-text>
       Before proceeding.Please check your email for a verification link
       </v-card-text>
       <v-divider class="mx-4"></v-divider>
       <v-card-actions>
         If you did not receive email
         <v-form v-on:submit.prevent="resendEmail">
          <v-btn        
            text
            type="submit"
            color="blue accent-4" >
              Click here to Resend email
          </v-btn>
         </v-form>
    </v-card-actions>
   </v-card>

   <v-snackbar
                      v-model="snackbar"
                      :color="snackbar_color"                      
                      rounded="pill"
                      top
                    >
                    {{ text }}

                    <template v-slot:action="{ attrs }">
                      <v-btn
                        color="white"
                        icon
                        v-bind="attrs"
                        @click="snackbar = false"
                      >
                      <v-icon>mdi-window-close</v-icon>
                      </v-btn>
                    </template>
                  </v-snackbar>
   </v-col>
  </v-row>
</v-container>
  </div>
</template>

<script>
//import { mapState } from "vuex";
import Authentication from '../../api/Authentication';
export default {
  data() {
      return{
                valid: true,              
                loading:false,
                snackbar:false,
                snackbar_color:'',
                text:'',
      }
  },
 
 
  methods: {
     
    resendEmail: function () {
         console.log(this.user)
         //console.log(this.$store.state.userProfile)
      this.loading = true;
      Authentication.resendEmail().then((response) => {
          console.log('response data :',response);
          if (response.status == 201) {
            this.loading = false;
            this.snackbar = true;
            this.snackbar_color = "success";
            this.text = response.data.message;
          }
        })
        .catch((error) => {
          //console.log('Error message :', error.response.data.message);
          this.loading = false;
          this.snackbar = true;
          this.snackbar_color = "error";
          this.text = error.response.data.message;
        });
    },
  },
};
</script>

