export default {
    data () {
        return{
            loading:false,
            snackbar:false,
            text:'',
            snackbar_color:null,
            enquiry:{
                name:'',
                contact:'',
                city:'',
                enquiryText:'',
            },
            nameRules: [
                         v => !!v || 'name is required',        
                       ],
            cityRules: [
                        v => !!v || 'City is required',   
                       ],            
            contactRules: [
                        v => !!v || 'Contact Number is required.',
                        v => !isNaN(parseInt(v)) || 'Contact Number must be digit.', 
                        v => ( v && v.length >= 10 ) || "Contact Number must have atleast 10 digit",
                        v => ( v && v.length <= 10 ) || "Contact Number exceeds maximum allowed number",          
                          ], 
            enquiryRules: [
                        v => !!v || 'Enquiry is required.',         
                          ], 
        }
       
    },
    methods:{
        clearAllField(){
                            this.enquiry.name='';
                            this.enquiry.contact='';
                            this.enquiry.city='';
                            this.enquiry.enquiryText='';
                        }
               
    }
}