<template>
  <div>
    <v-banner
 sticky
 dark
 rounded
 color="teal lighten-3"
 >
 <span class="title">Account Verification</span>
</v-banner>

<v-container>
  <v-row justify="center">
    <v-col cols="12" md="6" lg="6" xl="6" sm="12">   
   <v-card elevation="1" max-width="500">
     <v-progress-linear
        :active="loading"
        :indeterminate="loading"
        absolute
        top
        color="blue accent-4" />
          <div v-if="text === ''">
              <v-card-text class="text-center">
                  <b class="indigo--text darken-4 ">Your account being verifing please wait....</b>
              </v-card-text>
          </div>
        <div v-else>
         <v-card-text>
           <div v-if="status=== 201" class="text-center">
                  <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                    <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
                    <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
                  </svg> 
                  <b class=" success--text">{{text}}</b>                 
           </div>
                   
        </v-card-text>
        </div>    
              
      
   </v-card>
   
   </v-col>
  </v-row>
</v-container>
  </div>
</template>

<script>
//import { mapState } from "vuex";
import Authentication from '../../api/Authentication';
export default {
  data() {
      return{
          valid: true,
               user:{
                      email:'',
                      token:'',                      
                    },
                loading:false,
                status:'',               
                text:'',
      }
  },
 
  mounted() {
    this.getToken();
     this.getEmail();
     this.VerifyUser();
  },
  methods: {
      getEmail(){
                this.user.email = this.$route.query.email;
      },
    getToken() {
                this.user.token = this.$route.query.token;
    },
    VerifyUser: function () {         
      this.loading = true;
      Authentication.verifyUser(this.user).then((response) => {          
          if (response.status == 201) {
            //console.log(response.status);
            this.loading = false; 
            this.status= response.data.status_code;          
            this.text = response.data.message;
            
          }
        })
        .catch((error) => {
          //console.log('Error message :', error.response.data.message);
          this.loading = false;
          this.text = error.response.data.message;
        });
    },
  },
};
</script>
<style >
.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #7ac142;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  margin: 5% auto;
  box-shadow: inset 0px 0px 0px #7ac142;
  animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%, 100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #7ac142;
  }
}
</style>

