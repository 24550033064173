<template>
   <div id="admin">
       <v-app-bar
         app       
        height="72"
        >
      <v-app-bar-nav-icon  @click="mini = !mini"></v-app-bar-nav-icon>
      <v-spacer></v-spacer>

      <v-btn icon>
        <v-icon>mdi-bell</v-icon>
      </v-btn>

      <v-btn icon>
        <v-icon>mdi-cog</v-icon>
      </v-btn>
      
    </v-app-bar> 

    <v-navigation-drawer
          app         
          left                       
        :mini-variant.sync="mini"      
        permanent 
               
      width="250"
      >
      <v-list color="blue">
        <v-list-item class="px-2" >
            <v-list-item-avatar>
           <v-img src="../../assets/100logo.png"></v-img>
        </v-list-item-avatar>
         <v-list-item-content>
                <v-list-item-title>100 Miles Realtors</v-list-item-title>
        </v-list-item-content>
        </v-list-item>
      </v-list>

        <v-divider></v-divider>
        <v-list-item class="px-2" two-line>
        <v-list-item-avatar>
          <v-img :src="imgURL+'/'+userProfile.Id+'/avatar/'+userProfile.avatar"></v-img>
        </v-list-item-avatar>
<v-list-item-content>
        <v-list-item-title class="teal--text accent-4">{{userProfile.Name}}</v-list-item-title>
        <v-list-item-subtitle>{{userProfile.Email}}</v-list-item-subtitle>
</v-list-item-content>
        <v-btn
          icon
          @click="mini = !mini"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </v-list-item>
        <v-divider></v-divider>

        <v-list 
        dense
        nav>
        
      <div >
        <v-list-item
          v-for="item in propertyNav"
          :key="item.title"
          link
           :to="item.link"
        >
          <v-list-item-icon>
            <v-icon :class="item.color">{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title :class="item.color">{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
       </div>
       <v-divider></v-divider>
       <v-list-item  
         link
         :to="{name:'userprofilesetting'}">
            <v-list-item-icon>
            <v-icon class="blue--text darken-4">mdi-account-circle</v-icon>
          </v-list-item-icon>
            <v-list-item-title class="blue--text darken-4">Profile Setting</v-list-item-title>
           </v-list-item>
       <v-divider></v-divider>

         <v-list-item  
         link
         @click="logOut">
            <v-list-item-icon>
            <v-icon class="red--text darken-4">mdi-logout-variant</v-icon>
          </v-list-item-icon>
            <v-list-item-title class="red--text darken-4">Log Out</v-list-item-title>
           </v-list-item>
        </v-list>
      </v-navigation-drawer>
       
    <v-main class="px-2 py-2">
        <router-view></router-view>
    </v-main>
   </div>
</template>

<script >
import {mapState,mapActions} from 'vuex'
//import Authentication from '../../api/Authentication';
export default {
  name:'User',

  data() {
      return{
           imgURL:'https://100milesrealtors.com/routes/storage/images', 
           
        propertyNav: [
                { title: 'Dashboard', icon: 'mdi-view-dashboard', link:'/user/userdashboard',color:'indigo--text darken-4' }, 
                { title: 'My Property', icon: 'mdi-home', link:'/user/home',color:'indigo--text darken-4' },
                { title: 'Add Property', icon: 'mdi-bank-plus',link:'/user/addproperty',color:'indigo--text darken-4' },
                
              ],
        
        mini: true,
      }
  },
   computed:{
             ...mapState({
                          userProfile: state => state.authenticate.userProfile,                          
                        })
  },
   mounted() {   
     this.getUser();
   },
  methods:{
            ...mapActions(['logOut',
                           'getUser',
            ]),          
 /*logOut : function(){
  Authentication.logout().then(response => {    
    localStorage.removeItem('token')
    this.$store.commit('SET_USER',"null") 
    this.$store.commit('LOG_OUT_MESSAGE',response.data.message) 
    //console.log(response.data)
    this.$router.push('/auth/login');        
  });   
    
}*/
  }
}
</script>

<style scoped>

</style>