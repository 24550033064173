var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',[_c('v-card',{staticClass:"rounded-lg card-border my-1 py-2 px-2",attrs:{"light":"","elevation":"1"}},[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":{
          name: 'property',
          params: {
            title:
              _vm.property.propertytype.property_name + 'for' + _vm.property.pfor,
            id: _vm.property.basic_property_id,
            imgUrl:
              _vm.property.user_id +
              '/' +
              _vm.property.basic_property_id +
              '/' +
              _vm.property.image_name,
          },
        }}},[_c('v-img',{staticClass:"rounded-lg",attrs:{"src":_vm.imgURL +
            '/' +
            _vm.property.user_id +
            '/' +
            _vm.property.basic_property_id +
            '/' +
            _vm.property.image_name,"height":"250","width":"350"}}),_c('v-card-title',{staticClass:"title-color px-2"},[(_vm.property.bedroom)?_c('span',[_vm._v(" "+_vm._s(_vm.property.bedroom)+" BHK ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.property.propertytype.property_name)+" for "+_vm._s(_vm.property.pfor)+" ")])],1),_c('v-card-actions',[_c('v-icon',{attrs:{"left":"","small":"","color":"\n       blue-grey accent-4"}},[_vm._v(" fas fa-map-marker-alt ")]),_vm._v(" "+_vm._s(_vm.property.city)+" "),_c('v-icon',{staticClass:"ml-4",attrs:{"left":"","small":"","color":"\n       blue-grey accent-3"}},[_vm._v(" fas fa-rupee-sign ")]),_vm._v(" "+_vm._s(this.editedPrice)+" "),_c('v-spacer')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }