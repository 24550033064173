export const SET_TOKEN = (state, token) => {
  
    state.token = token;
}
export const SET_USER = (state, userProfile) => {
   
    state.userProfile = userProfile;
}


export const SET_MESSAGE = (state, message) => {
   
    state.message = message;
}

export const SET_ERROR_MESSAGE = (state, error_message) => {
   
    state.error_message = error_message;
}
export const SET_ERROR_CONTACT = (state, error_message) => {
   
    state.error_message_contact = error_message;
}
export const SET_ERROR_EMAIL = (state, error_message) => {
   
    state.error_message_email = error_message;
}
export const LOG_OUT_MESSAGE = (state, logout_message) => {
   
    state.logout_message_email = logout_message;
}

/* ************************** Admin Section ************************* */
export const SET_ALL_USER = (state, allUsers) => {
   
    state.allUsers = allUsers;
}