<template>
<div>
  <HomeAppBar/>

   <div class="home-container align-link-center">
      <v-form>
    <v-container class="search-bg">
      <SearchProperty/>
     
    </v-container>
     </v-form>
  </div>
     <v-container>
    <v-layout>
      <v-row>
          <DisplayProperty v-for="property in allSearchProperty" :key="property.id" :property="property"/>
      </v-row>
    </v-layout>
  </v-container> 
</div>
</template>
<script>
import HomeAppBar from '@/components/HomeAppBar.vue'
import SearchProperty from '@/components/SearchProperty.vue'
import DisplayProperty from '@/components/DisplayProperty.vue'
import {mapState} from 'vuex';
export default {
   components: {
                HomeAppBar,
                SearchProperty,
                DisplayProperty,  
                },
                computed:{
            ...mapState({
                          allSearchProperty: state => state.property.allSearchProperty,
            })
    }, 
}
</script>