<template>
    <v-data-table
        :headers="headers"
        :items="allEnquiry"
        :items-per-page="6"
        class="elevation-2">
        <template v-slot:top>
          <v-toolbar flat>
        <v-toolbar-title>All Enquiries</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>               
      </v-toolbar>
       <v-progress-linear
      :active="loading"
      :indeterminate="loading"
      color="primary"
      v-if="loading"
      top
    />
    </template>
    <template v-slot:item.actions="{ item }" >      
    <v-tooltip bottom v-if="item.enq_status == 1">
        <template v-slot:activator="{ on, attrs }">
       <v-btn            
            color="success"
            icon 
             v-bind="attrs"
            v-on="on"         
            @click="upadateEnquiryStatus(item.id,{'enquiry_status':2})"
          >
            <v-icon>
              mdi-check-circle
            </v-icon>            
          </v-btn>                       
        </template>
         <span>Contacted</span>
          </v-tooltip>
    <div v-if="item.enq_status == 2">
      <v-tooltip 
          bottom                         
         >
        <template v-slot:activator="{ on, attrs }">
       <v-btn            
            color="blue"
            icon 
             v-bind="attrs"
            v-on="on"         
            @click="upadateEnquiryStatus(item.id,{'enquiry_status':3})"
          >
            <v-icon>
              mdi-handshake
            </v-icon>            
          </v-btn>          
        </template>
         <span>Deal Closed</span>
          </v-tooltip>

          <v-tooltip 
          bottom               
         >
        <template v-slot:activator="{ on, attrs }">
       <v-btn            
            color="red"
            icon 
             v-bind="attrs"
            v-on="on"         
            @click="upadateEnquiryStatus(item.id,{'enquiry_status':4})"
          >
            <v-icon>
              mdi-close-circle
            </v-icon>            
          </v-btn>          
        </template>
         <span>Deal Reject</span>
          </v-tooltip>
    </div>
    </template>


  </v-data-table>      
</template>

<script>
import {mapState,mapActions} from 'vuex';
import Property from '../../api/Property';
  export default {
    data () {
      return {
        loading: false,
        headers: [
          {
            text: 'ID',
            align: 'center',
            sortable: false,
            value: 'id',
          },
          { text: 'Property ID', value: 'basic_property_id', sortable: false,  },
          { text: 'Name', value: 'name', sortable: false, },
          { text: 'City', value: 'city', sortable: false, },
          { text: 'Contact', value: 'contact', sortable: false, },
          { text: 'Enquiry', value: 'enquiry', sortable: false, },
          { text: 'Enquiry Status', value: 'enquirystatus.enquiry_status', sortable: false, },
         
          { text: 'Action', value: 'actions', sortable: false, },
         
       
        ],       
      }     
    },

    computed:{
            ...mapState({
                          allEnquiry: state => state.property.allEnquiry,
            })
    },
    mounted(){
                  this.showAllEnquiry();
    },
    methods:{
            ...mapActions([
                            'showAllEnquiry']),
                            upadateEnquiryStatus(id,status)
                            {
                            this.loading=true;
                           
                              Property.upadateEnquiryStatus(id,status)         
                          .then(response =>  { 
                            console.log(response.data)                           
                                    if(response.data.status_code == 201)
                                    { this.loading=false;
                                    this.text = response.data.message;
                                    this.snackbar = true;
                                    this.snackbar_color = 'success' 
                                      this.showAllEnquiry();  
                                    }                  
                                  }).catch(err => {
                                    this.loading=false;
                                            this.text = err.response.data.message
                                            this.snackbar = true;
                                            this.snackbar_color = 'error'                  
                                  });                              
                            }
    }
  }
</script>

<style>

</style>