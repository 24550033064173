import Property from '../../../api/Property'

export const getProperty = ({commit}) => {
   
    Property.getProperty().then(response => {
      commit('SET_POPERTYLIST',response.data);
    })
    
  }

  export const showProperty =({commit},pageNumber)=>{
    Property.showProperty(pageNumber).then(response => {
      //console.log(response.data);
      commit('SET_PROPERTY',response.data);    
      
    })
  }
  export const currentPage =({commit},{data})=>{
 
      commit('SET_CURRENT_PAGE',data);    
          
  }
  export const viewPropertyDetails =({commit},{propertyId})=>{
    Property.propertyDetails(propertyId).then(response => {       
      //console.log(response.data);
      commit('SET_BASIC_DETAILS',response.data.basicDetails);
      commit('SET_AMENITY_DETAILS',response.data.amenityDetails);
      commit('SET_PROPERTY_PHOTO',response.data.propertyPhoto);              
      
    })
    
  }

 /* ******************* Authenticated User Property Actions ********************* */
 export const showUserProperty =({commit})=>{
  Property.showUserProperty().then(response => {    
    commit('SET_USER_PROPERTY',response.data);  
  }).catch(function (error) {
    if (error.response) {       
      console.log('error data :',error.response.data.message);
      if(error.response.status === 401){
       // localStorage.removeItem('token');
       // this.$router.push('/auth/login');  
      }
                
      console.log('error status :',error.response.status);     
    }    
    else {       
      console.log('Error message :', error.message);
    }
    
  });
}
 
/* ************************** Admin Section Actions *********************** */
export const showAdminProperty =({commit})=>{
  Property.showAdminProperty().then(response => {
    
    commit('SET_ADMIN_PROPERTY',response.data);
      
  }).catch(function (error) {
    if (error.response) {       
      console.log('error data :',error.response.data.message);
      if(error.response.status === 401){
       // localStorage.removeItem('token');
        this.$router.go(0);  
      }
                
      console.log('error status :',error.response.status);     
    }    
    else {       
      console.log('Error message :', error.message);
    }
    
  });
}

export const adminPropertyDetails =({commit},{propertyId})=>{
  Property.adminPropertyDetails(propertyId).then(response => {       
    //console.log(response.data);
    commit('SET_BASIC_DETAILS',response.data.basicDetails);
    commit('SET_AMENITY_DETAILS',response.data.amenityDetails);
    commit('SET_PROPERTY_PHOTO',response.data.propertyPhoto);              
    
  })
  
}


export const showAllEnquiry =({commit})=>{
  Property.showAllEnquiry().then(response => {
    
    commit('SET_ALL_ENQUIRY',response.data);
      
  }).catch(function (error) {
    if (error.response) {       
      console.log('error data :',error.response.data.message);
      if(error.response.status === 401){     
        this.$router.go(0);  
      }               
      console.log('error status :',error.response.status);     
    }    
    else {       
      console.log('Error message :', error.message);
    }
    
  });
}