import Amenity from '../../../api/Amenity'

export const basicAmenity = ({commit}) => {
    Amenity.basicAmenity().then(response => {
      commit('SET_BASIC_AMENITY',response.data);
    })    
  }

  export const safetyAmenity = ({commit}) => {
    Amenity.safetyAmenity().then(response => {
      commit('SET_SAFETY_AMENITY',response.data);
    })    
  }

  export const parkingAmenity = ({commit}) => {
    Amenity.parkingAmenity().then(response => {
      commit('SET_PARKING_AMENITY',response.data);
    })    
  }

  export const waterAmenity = ({commit}) => {
    Amenity.waterAmenity().then(response => {
      commit('SET_WATER_AMENITY',response.data);
    })    
  }

  export const flooringAmenity = ({commit}) => {
    Amenity.flooringAmenity().then(response => {
      commit('SET_FLOORING_AMENITY',response.data);
    })
    
  }
  export const kitchenAmenity = ({commit}) => {
    Amenity.kitchenAmenity().then(response => {
      commit('SET_KITCHEN_AMENITY',response.data);
    })
    
  }
  export const otherAmenity = ({commit}) => {
    Amenity.otherAmenity().then(response => {
      commit('SET_OTHER_AMENITY',response.data);
    })
    
  }
  export const popAmenity = ({commit}) => {
    Amenity.popAmenity().then(response => {
      commit('SET_POP_AMENITY',response.data);
    })    
  }

  export const furnishedAmenity = ({commit}) => {
    Amenity.furnishedAmenity().then(response => {
      commit('SET_FURNISHED_AMENITY',response.data);
    })   
  }

  export const maintenanceAmenity = ({commit}) => {
    Amenity.maintenanceAmenity().then(response => {
      commit('SET_MAINTENANCE_AMENITY',response.data);
    })    
  }