<template>
    <div>
      
 <v-col cols="12" md="4" lg="4" xl="4"> 
                
     <v-card class="mx-auto my-12"
    min-width="320">
    <v-img
    
      :src="imgURL+'/'+userProperty.user_id+'/'+userProperty.basic_property_id+'/'+userProperty.image_name"
       height="200px"
    width="350px"
    ></v-img>

    <v-card-title>
     <span  class="blue--text">{{userProperty.propertytype.property_name}} for {{userProperty.pfor}}</span>
    </v-card-title>
    <v-card-subtitle v-if="userProperty.bedroom">     
     {{userProperty.bedroom}} BHK {{userProperty.propertytype.property_name}} 
    </v-card-subtitle>
    <v-divider/>
    <v-card-text>
       <span class="light-blue--text lighten-1"> Location : </span>{{userProperty.city}}<br>
      <span class="light-blue--text lighten-1"> Property type : </span> {{userProperty.ptype}}<br>          
      <span class="light-blue--text lighten-1">Price : </span>₹ {{userProperty.price}}<br>
      <span class="light-blue--text lighten-1">Facing : </span>{{userProperty.facing}}<br>
      
    </v-card-text>
    <v-divider/>
    <v-card-actions>
     
     <!--<router-link :to="{name:'userProperty',params:{id: userProperty.basic_property_id}}">
        <p class=" font-weight-bold text-uppercase deep-orange--text">
          view Details
        </p>
    </router-link>-->
      <template>
        <v-row
          align="center"
          justify="center"
         class="px-1 py-2"
        >
        <v-btn v-if="userProfile.role == 1"
            tile
            color="info"
            text          
            :to="{name:'adminProperty',params:{id:userProperty.basic_property_id}}"
          >
            <v-icon left>
              mdi-eye
            </v-icon>
            Details
          </v-btn>
          <v-btn
          v-else
            tile
            color="info"
            text          
            :to="{name:'userProperty',params:{id:userProperty.basic_property_id}}"
          >
            <v-icon left>
              mdi-eye
            </v-icon>
            Details
          </v-btn>
          <v-btn
            tile
            color="success"
            text
          >
            <v-icon left>
              mdi-pencil
            </v-icon>
            Edit
          </v-btn>

          <v-btn
            tile
            color="red"
            text
            @click="deletePropertyDialog"
          >
            <v-icon left>
              mdi-delete
            </v-icon>
            Delete
          </v-btn>
        </v-row>
      </template>
    </v-card-actions>
  </v-card>         
</v-col>
<v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="headline">Are you sure you want to delete this item?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="this.dialogDelete = false">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="deleteProperty">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
    </div>
</template>
<script>
 import { mapState, } from 'vuex';
 import Property from '../api/Property'
export default {
  props:['userProperty'],
  data: () => ({
      imgURL:'https://100milesrealtors.com/routes/storage/images',
      show: false,
      dialogDelete: false,
      rlid:'',
    }), 
    computed:{
             ...mapState({
                          userProfile: state => state.authenticate.userProfile,                          
                        })
  },
    methods:{
      deletePropertyDialog () {       
        this.dialogDelete = true
      },
      

      deleteProperty : function(){ 
        this.dialogDelete = false      
          console.log(this.userProperty.basic_property_id)
          Property.deleteUserProperty(this.userProperty.basic_property_id)
          .then(response =>  {                                      
                  if(response.data)
                  {
                    this.$router.go(0);
                     console.log(response.data)                                                      
                  }
                  
                }).catch(err => {
                         console.log(err.response.data.message)
                         // this.snackbar = true;
                          //this.snackbar_color = 'error'
                          
                  
                });
        }
      }
    
}
</script>