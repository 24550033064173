<template>

      <v-card flat width="700"  class="py-2 mx-auto">
        <v-row >
         <v-col md="5" class="justify-center align-center d-flex flex-column" >
          
             <v-img
             src="../../assets/logo.png"
              contain
              max-height="200"  
              max-width="200"                       
              transition="scale-transition"
             /> 
         
             <v-img
             src="../../assets/name.png"
              contain
              max-width="300"            
              transition="scale-transition"
             />
        
         </v-col>

         <v-divider vertical inset/>

        <v-col md="6" class="mt-3 ">
          <v-progress-linear
            :active="loading"
            :indeterminate="loading"
            absolute
            top
            color="blue accent-4"
          ></v-progress-linear>         
      <v-card-title> <span class="primary--text">Sign In</span> </v-card-title>
            <v-divider />
          <v-card-text>
            <v-form
              v-on:submit.prevent="login"
              ref="form"
              v-model="valid"
              lazy-validation
            >
              <v-text-field
                v-model="user.email"
                :rules="emailRules"
                label="E-mail"
                prepend-icon="mdi-email"
                class="mb-4"
              />
              <v-text-field
                v-model="user.password"
                :rules="passwordRules"
                label="Password"
                :counter="8"
                :type="ShowPassword ? 'text' : 'password'"
                prepend-icon="mdi-lock"
                :append-icon="ShowPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="ShowPassword = !ShowPassword"
                class="mb-4"
              />
               <div class="float-right mb-2">
                <router-link to="/auth/forgotpassword" class="routerlink caption"
                  >Forgot Password?</router-link
                >               
              </div>

              <!--<v-checkbox
                v-model="user.remember_me"
                label="Remember Me"
                color="info"
                value=""
                class="mb-4"
              />-->
             
              <v-btn
                color="info"
                type="submit"
                rounded
              
                block
                @click="validate"
                :disabled="!valid"
              >
                Sign In
              </v-btn>
            </v-form>
          </v-card-text>
      <v-divider/>
          <v-card-actions>
            <div class="login-action">             
              <div>
                Don't have an account ?
                <router-link to="/auth/register" class="routerlink"
                  >Create Account</router-link
                >
              </div>
            </div>
          </v-card-actions>
        </v-col>
        </v-row>
      <v-snackbar v-model="snackbar" :color="snackbar_color" rounded="pill" top>
        {{ text }}

        <template v-slot:action="{ attrs }">
          <v-btn color="white" icon v-bind="attrs" @click="snackbar = false">
            <v-icon>mdi-window-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
      </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Authentication from "../../api/Authentication";

export default {
  name: "login",

  data() {
    return {
      valid: true,
      ShowPassword: false,
      loading: false,
      snackbar: false,
      snackbar_color: "",
      text: "",
      user: {
        email: "",
        password: "",
        remember_me: false,
      },

      errors: [],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+/.test(v) || "E-mail must be valid.",
      ],

      passwordRules: [
        (v) => !!v || "Password is required",
        (v) => v.length >= 8 || "password at least 8 characters.",
      ],
    };
  },
  computed: {
    ...mapState({
      logout_message: (state) => state.authenticate.logout_message,
    }),
  },
  mounted() {},
  methods: {
    validate() {
      this.$refs.form.validate();
    },
    ...mapActions(["login"]),
    /*signIn() {
            try {              
              this.login(this.user)             
              this.$router.push('/user');
              } 
            catch (error) {
              console.log(error);
            }
    }*/

    login: function () {
      this.loading = true;
      Authentication.login(this.user)
        .then((response) => {
          if (response.data.access_token !== null) {
            console.log(response.data);
            this.loading = false;
            this.$store.commit("SET_TOKEN", response.data.access_token);
            if (response.data.role == 1) {
              console.log(response.data.role);
              localStorage.setItem("token", response.data.access_token);
              localStorage.setItem("rlid", response.data.role);
              localStorage.setItem("vrid", response.data.vrid);
              this.$router.replace("/admin/dashboard");
            } else {
              localStorage.setItem("token", response.data.access_token);
              localStorage.setItem("rlid", response.data.role);
              localStorage.setItem("vrid", response.data.vrid);
              this.$router.replace("/user/home");
            }
          } else {
            this.loading = false;
            this.$router.push("/auth/login");
          }
          // commit('SET_TOKEN',response.data.access_token)
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
          this.snackbar_color = "error";
          //this.text = err.response.data.message;
          this.snackbar = true;
        });
    },
  },
};
</script>