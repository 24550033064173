import '@fortawesome/fontawesome-free/css/all.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
            iconfont : 'md' || 'fa'
    },
    breakpoint: {
        mobileBreakpoint: 'sm',// This is equivalent to a value of 960
        thresholds: {
            xs: 340,
            sm: 540,
            md: 800,
            lg: 1280,
          },
          scrollBarWidth: 24,
      },
    theme: {
        themes: {
            light: {
                background: colors.shades.white,
            },
            dark: {
                background: colors.shades.black,
            }
        }
    }
});
