<template>
  <div><v-progress-linear
                    :active="loading"
                    :indeterminate="loading"
                    absolute
                    top
                    color="blue accent-4"
                  ></v-progress-linear> 
                  
                  <v-snackbar
                      v-model="snackbar"
                      color="error"                      
                      rounded="pill"
                      top
                    >
                    {{ text }}

                    <template v-slot:action="{ attrs }">
                      <v-btn
                        color="white"
                        icon
                        v-bind="attrs"
                        @click="snackbar = false"
                      >
                      <v-icon>mdi-window-close</v-icon>
                      </v-btn>
                    </template>
                  </v-snackbar>
    <v-form
      @submit.prevent="searchProperty" 
      ref="form"
      enctype="multipart/form-data"
      v-model="valid"
      lazy-validation> 

      <v-row align="center">
        <v-radio-group 
        v-model="search.pfor" 
        row 
        dark
        :rules="pforRules">
          <v-radio label="Rent" value="rent"></v-radio>
          <v-radio label="Buy" value="sale"></v-radio>
        </v-radio-group>
      </v-row>
      <v-row align="center">
        <v-col class="d-flex" cols="12" sm="3">
          <v-select 
          :items="states" 
           item-text="state_name"
          item-value="id"
          :rules="stateRules"
          label="Select State"
          v-model="state"         
          dense solo rounded></v-select>
        </v-col>
<v-col class="d-flex" cols="12" sm="3">
         <v-select
          
          :items="cities"
          v-model="search.city"
          item-text="city_name"
          item-value="id"
          label="Select City"
          :rules="districtRules"
          @click="getCityList" 
          dense solo rounded/>
        </v-col > 
        <v-col class="d-flex" cols="12" sm="3">
          <v-select 
         v-model="search.propertyType"    
          :items="propertylist"
          item-text="property_name"
          item-value="id"
          label="Select Property"
          :rules="propertyRules"          
          dense solo rounded></v-select>
        </v-col>
        <v-col class="mb-5 d-flex" cols="12" sm="3">
          <v-btn 
          type="submit"
          block 
          rounded 
          dark 
          color="indigo"
          :disabled="!valid"
          @click="validate">
            <v-icon left> mdi-magnify </v-icon>
            Search
          </v-btn>
        </v-col>
      </v-row>
      
    </v-form>
  </div>
</template>

<script >
import {mapState,mapActions} from 'vuex'
import Property from '../api/Property'
import AddPropertyMixin from '../mixins/AddPropertyMixin';
export default {
 name:'SearchProperty',
  mixins:[AddPropertyMixin],
  data() {
      return{
        loading:false,
        snackbar:false,
        text:'',
          state:'',
          search:{
                  city:'',
                  pfor:'',
                  propertyType:'',
          },
          
        mini: true,
         valid: true, 
      }
  },
  computed:{
             ...mapState({
                         propertylist: state => state.property.propertylist,
                          states: state => state.city.states,
                          cities: state => state.city.cities,                          
                        })
  },
   mounted() {   
   this.getProperty();
  this.getState();
   },
  methods:{
            ...mapActions(['getCity',
                            'getState',
                            'getProperty',
            ]),
             validate () {
                          this.$refs.form.validate();                         
                        },  
             getCityList(){
               console.log(this.state)
                   this.getCity({
                    cityId: this.state,                    
                   });                                     
                 }, 
                  searchProperty()
                 { 
                   this.loading=true,                                  
                    Property.searchProperty(this.search).then(response =>{                      
                      //console.log('response got....',response.data);
                                    if(response.data.status_code == 200)
                                      {
                                        console.log('201 response got....',response.data.search_property)
                                        this.loading=false,                                       
                                        this.$store.commit('SET_SEARCH_PROPERTY',response.data.search_property);
                                        this.$router.replace('searchproperty')
                                      }
                                      if(response.data.status_code == 204)
                                      {
                                        console.log('204 response got....',response.data)
                                        this.loading=false,
                                        this.snackbar=true,
                                        this.text=response.data.message;
                                      }
                                                                                                                                                                              
                    }).catch(function (error) {   
                                console.log('Error message :', error.message);                                    
                                            });
                }        

  }
}
</script>

<style scoped>

</style>