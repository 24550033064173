import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import ShowSearchProperty from '../views/ShowSearchProperty.vue'
import Login from '../views/Auth/Login.vue'
import Register from '../views/Auth/Register.vue'
import ForgotPassword from '../views/Auth/ForgotPassword.vue'
import ResetPassword from '../views/Auth/ResetPassword.vue'
import VerifyEmail from '../views/Auth/VerifyEmail.vue'
import VerifyAccount from '../views/Auth/VerifyAccount.vue'
import PageNotFound from '../views/PageNotFound.vue'
import Finance from '../views/Finance.vue'
import About from '../views/About.vue'
import ContactUs from '../views/ContactUs.vue'
import AppDownload from '../views/AppDownload.vue'
import Services from '../views/Services.vue'
import PrivacyPolicy from '../views/PrivacyPolicy.vue'
import TermsAndConditions from '../views/TermsAndConditions.vue'
import Auth from '../views/Auth/Auth.vue'
import ShowPropertyDetails from '../views/ShowPropertyDetails.vue'

Vue.use(VueRouter)

const routes = [
  
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: "100 Miles Realtors",
      metaTags: [
        {
          property: "og:title",
          content: "title",
        },
        {
          property: "og:url",
          content: "https://100milesrealtors.com/ShowPropertyDetails/:id",
        },
        {
          property: "og:image",
          content:
            "https://100milesrealtors.com/routes/storage/images/:imgUrl",
        },
      ],
    },
  },
  {
    path: '/searchproperty',
    name: 'searchproperty',
    component: ShowSearchProperty,
  },
  

/* ********************************** OTHER PAGES ******************** */
{
  path: '/hmp',      
  component: () => import('../views/OtherPages.vue'),
  children: [
    {
      path: '/ShowPropertyDetails/:id',
      name:'property',      
      props:true, 
      component:ShowPropertyDetails
    },
    {
      path: 'about',    
      component: About
    },
    {
      path: 'finance',       
      component:Finance
    },
    {
      path: 'PrivacyPolicy',       
      component:PrivacyPolicy
    },
    {
      path: 'TermsAndConditions',       
      component:TermsAndConditions
    },
    {
      path: 'services',       
      component:Services
    },
    {
      path: 'download-app',       
      component:AppDownload
    },
    {
      path: 'contactus',       
      component:ContactUs
    },
  ]       
},
  /* ********************************** USER SECTION ******************** */
  {
    path: '/user',      
    component: () => import('../views/user/User.vue'),
    children: [
      {        
        path: 'home', 
        name:'userhome'  ,           
        component: () => import('../views/user/UserHome.vue'),
        beforeEnter(to, from, next) {
          if(vrid() == 1)
        {      
          next();
        }
        else{
          next({path: "/user/verify-account"})
        }
        },
      },
      {
        path: 'verify-email',
        name:'verify-email',
        component: VerifyEmail      
        //props:true, 
        //component:ShowPropertyDetails
        
      },
      {
        path: 'verify-account',
        name:'verify-account',
        component: VerifyAccount 

      },
      {
        path: 'viewpropertydetails/:id',
        name:'userProperty',
        component: () => import('../views/admin/ViewPropertyDetails.vue'),      
        props:true, 
        //component:ShowPropertyDetails
        
      },
      {        
        path: 'addproperty',
        //component: AddProperty,
        component: () => import('../views/user/AddProperty.vue'),
        beforeEnter(to, from, next) {
          if(vrid() == 1)
        {      
          next();
        }
        else{
          next({path: "/user/verify-account"})
        }
        },
      },
      {        
        path: 'userdashboard',
        component: () => import('../views/user/UserDashboard.vue'),
        beforeEnter(to, from, next) {
          if(vrid() == 1)
        {  
          //console.log('before route enter user dashboard executed');
          next();
        }
        else{
            next({path: "/user/verify-account"})
           }
        },
      },
      {        
        path: 'profilesetting',
        name:'userprofilesetting',    
        //component: ProfileSetting,
        component: () => import('../components/ProfileSetting'),
      },
    ],
    meta: { authOnly: true },
    beforeEnter(to, from, next) {
      if(role() == 2||role() == 3||role() == 4)
    {      
      next();
    }
    else{
     localStorage.removeItem('token');
     localStorage.removeItem('rlid');
     next({path: "/auth/login"})
    }
    },
    
  },

  {
  path: '/admin',      
  component: () => import('../views/admin/Admin.vue'),
  children: [    
    {        
      path: 'dashboard',
      name:'admindashboard',
      component: () => import('../views/admin/Dashboard.vue'),
      beforeEnter(to, from, next) {
        if(vrid() == 1)
      {      
        next();
      }
      else{
        next({path: "/user/verify-account"})
      }
      },
    },
    {        
      path: 'home',
      //component: AdminHome,
      component: () => import('../views/admin/AdminHome.vue'),
      beforeEnter(to, from, next) {
        if(vrid() == 1)
      {      
        next();
      }
      else{
        next({path: "/user/verify-account"})
      }
      },
    },
    {        
      path: 'addproperty',
      //component: AddProperty,
      component: () => import('../views/user/AddProperty.vue'),
      beforeEnter(to, from, next) {
        if(vrid() == 1)
      {      
        next();
      }
      else{
        next({path: "/user/verify-account"})
      }
      },
    },
    {        
      path: 'users',
      //component: AllUsers,
      component: () => import('../views/admin/AllUsers.vue'),
      beforeEnter(to, from, next) {
        if(vrid() == 1)
      {      
        next();
      }
      else{
        next({path: "/user/verify-account"})
      }
      },
    },
    {        
      path: 'allproperty',
      //component: AllProperty,
      component: () => import('../views/admin/AllProperties.vue'),
      beforeEnter(to, from, next) {
        if(vrid() == 1)
      {      
        next();
      }
      else{
        next({path: "/user/verify-account"})
      }
      },
    },
    
    {
    path: 'viewpropertydetails/:id',
    name:'adminProperty', 
    component: () => import('../views/admin/ViewPropertyDetails.vue'),     
    props:true, 
    //component:ViewPropertyDetails,
    beforeEnter(to, from, next) {
      if(vrid() == 1)
    {      
      next();
    }
    else{
      next({path: "/user/verify-account"})
    }
    },

    },
    {
    path: 'enquiries',  
      component: () => import('../views/admin/Enquiry.vue'),  
      beforeEnter(to, from, next) {
        if(vrid() == 1)
      {      
        next();
      }
      else{
        next({path: "/user/verify-account"})
      }
      },     
    },
    {        
      path: 'profilesetting',
        name:'adminprofilesetting',  
      //component: ProfileSetting,
      component: () => import('../components/ProfileSetting'),
    },
  ],
  meta: { authOnly: true },
  beforeEnter(to, from, next) {
    if(role() == 1)
  {      
    next();
  }
  else{
   localStorage.removeItem('token');
   localStorage.removeItem('rlid');
   next({path: "/auth/login"})
  }
  },  
},
  
{
    path: '/auth',   
    component: Auth,
    meta: { guestOnly: true },
    children: [
      {        
        path: 'login',
        name:'login',
        component: Login,       
      },
      {        
        path: 'register',
        component: Register,        
      },
      {        
        path: 'forgotpassword',
        component: ForgotPassword,
      },
      {        
        path: 'resetpassword',
        component: ResetPassword,
      },      
    ]       
  },  
  {
    path: "/:catchAll(.*)",
      name: "NotFound",
      component: PageNotFound,
      meta: {
        requiresAuth: false
      },    
    
    }
  
]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
function isLoggedIn() {
  return localStorage.getItem("token");
}

function role(){
  return localStorage.getItem("rlid");
}

function vrid(){
  return localStorage.getItem("vrid");
}
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.authOnly)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!isLoggedIn()) {
      next({
        path: "/auth/login",        
      });
    } else {        
      next();              
    }
  } else if (to.matched.some(record => record.meta.guestOnly)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (isLoggedIn()) {
      
      if(role() == 1)
    {      
      next({
        path: "/admin/dashboard", 
      });
    }
    else{
      next({
        path: "/user/home",       
      });      
    }
   
    } else {
      next();
    }
  } else {
    next(); // make sure to always call next()!
  }
});
export default router
