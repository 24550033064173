//import axios from './axios'
import axios from 'axios'
const amenety_list = 'amenity';

export default {
    basicAmenity(){
        return axios.get(`/${amenety_list}/basicamenity`)
    },
    safetyAmenity(){
        return axios.get(`/${amenety_list}/safetyamenity`)
    },
    parkingAmenity(){
        return axios.get(`/${amenety_list}/parkingamenity`)
    },
    waterAmenity(){
        return axios.get(`/${amenety_list}/wateramenity`)
    },
    flooringAmenity(){
        return axios.get(`/${amenety_list}/flooringamenity`)
    },
    kitchenAmenity(){
        return axios.get(`/${amenety_list}/kitchenamenity`)
    },
    otherAmenity(){
        return axios.get(`/${amenety_list}/otheramenity`)
    },
    popAmenity(){
        return axios.get(`/${amenety_list}/popamenity`)
    },
    furnishedAmenity(){
        return axios.get(`/${amenety_list}/furnishedamenity`)
    },
    maintenanceAmenity(){
        return axios.get(`/${amenety_list}/maintainanceamenity`)
    }
}