export const SET_POPERTYLIST = (state, propertylist) => {
    state.propertylist = propertylist;
}
export const PROPERTY_ERROR = (state, response) => {
    state.error = response;
}

export const SET_PROPERTY_MESSAGE = (state, response) => {
    state.property_message = response;
}

export const SET_PROPERTY = (state, allProperty) => {
    state.allProperty = allProperty;
}
export const SET_CURRENT_PAGE = (state, data) => {
    state.allProperty.current_page = data;
}
export const SET_SEARCH_PROPERTY = (state, allSearchProperty) => {
    state.allSearchProperty = allSearchProperty;
}
export const SET_PROPERTY_TYPE = (state, property_type) => {
    state.property_type = property_type;
}
export const SET_BASIC_DETAILS = (state, basicDetails) => {
    state.basicDetails = basicDetails;
}
export const SET_AMENITY_DETAILS = (state, amenityDetails) => {
    state.amenityDetails = amenityDetails;
}
export const SET_PROPERTY_PHOTO = (state, propertyPhoto) => {
    state.propertyPhoto = propertyPhoto;
}
/* ******************* Authenticated User Property Mutations ********************* */
export const SET_USER_PROPERTY = (state, userProperty) => {
    state.userProperty = userProperty;
}

/* ******************* Admin Property Mutations ********************* */
export const SET_ADMIN_PROPERTY = (state, allAdminProperty) => {
    state.allAdminProperty = allAdminProperty;
}
export const SET_ALL_ENQUIRY = (state, allEnquiry) => {
    state.allEnquiry = allEnquiry;
}