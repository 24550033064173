//import api from './Api'
import axios from 'axios'
export default {
    getProperty(){
        return axios.get('/propertytypelist');        
    },
    
    postEnquiry(fd){
        return axios.post(`/postEnquiry`,fd);  
    },
   
    showProperty(pageNumber){
        return axios.get('/showProperty?page='+pageNumber);        
    },
    searchProperty(search){
        console.log(`propertyType=${search.propertyType}&city=${search.city}&pfor=${search.pfor}`);
        return axios.get(`/searchProperty?propertyType=${search.propertyType}&city=${search.city}&pfor=${search.pfor}`);        
    },
    propertyDetails(id){            

        return axios.get(`/showPropertyDetails/${id}`);           
    },

    /* ************ authenticated user property ******* */
    showUserProperty(){            

        return axios.get('/showUserProperty');           
    },
    postProperty(fd){

        return axios.post(`/postProperty`,fd);        
    },
    postPropertyPhoto(propertyImage){
 
        return axios.post(`/postPropertyPhoto`,propertyImage);        
    },
    deleteUserProperty(id){            
  
        return axios.delete(`/deleteProperty/${id}`);           
    },

    /* *************************** admin section  ********************* */
    showAdminProperty(){            
 
        return axios.get('/adminProperty');           
    },
    adminPropertyDetails(id){            

        return axios.get(`adminPropertyDetails/${id}`);           
    },
    upadatePropertyStatus(id,status){
        return axios.put(`/upadatePropertyStatus/${id}`,status);
    },
    showAllEnquiry(){            
        return axios.get('/allEnquiry');           
    },
    upadateEnquiryStatus(id,status){
        return axios.put(`/upadateEnquiryStatus/${id}`,status);
    },
}