<template>
  <div id="registration">
    <v-card flat max-width="900" class="mx-auto" >
      <v-row class="">
        <v-col
          cols="12"
          md="4"
          class="justify-center align-center d-flex flex-column"
        >
          <v-img
            src="../../assets/logo.png"
            contain
            max-height="200"
            max-width="200"
            transition="scale-transition"
          />
          <v-img
            src="../../assets/name.png"
            contain
            max-width="300"
            transition="scale-transition"
          />
        </v-col>

        <v-divider vertical inset />
        <v-col cols="12" md="7" class="">
          <v-form
            v-on:submit.prevent="register"
            ref="form"
            v-model="valid"
            lazy-validation
          >
            <v-progress-linear
              :active="loading"
              :indeterminate="loading"
              absolute
              top
              color="blue accent-4"
            />

            <v-card-title> <span class="primary--text">Create New Account</span> </v-card-title>
            <v-divider />
            <v-card-text>
              <v-row class="d-flex">
                <v-col cols="12" md="6" lg="6" xl="6">
                  <div>
                    <v-select
                      v-model="user.role_id"
                      :items="role_id"
                      :rules="titleRules"
                      item-text="title"
                      item-value="id"
                      label="I Am "
                      single-line
                    ></v-select>
                  </div>

                  <v-text-field
                    v-model="user.email"
                    :rules="emailRules"
                    label="E-mail"
                    :error-messages="this.error_email"
                    prepend-icon="mdi-email"
                  />
                  <v-text-field
                    v-model="user.password"
                    :rules="passwordRules"
                    label="Password"
                    :counter="8"
                    :type="ShowPassword ? 'text' : 'password'"
                    prepend-icon="mdi-lock"
                    :append-icon="ShowPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="ShowPassword = !ShowPassword"
                  />
                </v-col>
                <v-col cols="12" md="6" lg="6" xl="6">
                  <!--<span class="red--text">{{error_message_email[0]}}</span>-->
                  <v-text-field
                    v-model="user.name"
                    :rules="nameRules"
                    label="Full Name"
                    prepend-icon="mdi-account-circle"
                  />
                  <v-text-field
                    label="Mobile No."
                    v-model="user.contact"
                    :counter="10"
                    :rules="contactRules"
                    :error-messages="this.error_contact"
                    prepend-icon="mdi-cellphone"
                  />
                  <!--<span class="red--text">{{error_message_contact[0]}}</span>-->

                  <v-text-field
                    label="Confirm Password"
                    v-model="user.password_confirmation"
                    :rules="confirmpasswordRules"
                    :type="ShowConfirmPassword ? 'text' : 'password'"
                    prepend-icon="mdi-lock"
                    :counter="8"
                    :error-messages="error_message.password"
                    :append-icon="
                      ShowConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'
                    "
                    @click:append="ShowConfirmPassword = !ShowConfirmPassword"
                  />
                </v-col>
                <v-btn
                  color="info"
                  type="submit"
                  rounded
                  large
                  block
                  @click="validate"
                  :disabled="!valid"
                >
                  Register
                </v-btn>
              </v-row>
            </v-card-text>
            <v-divider class="my-4"/>
            <v-card-actions class="">
              <div class="login-action">
                <div>
                Already have an account ?
                <router-link to="/auth/login" class="routerlink "
                  >Sign In</router-link
                ></div>
              </div>
            </v-card-actions>
          </v-form>
        </v-col>
      </v-row>
    </v-card>
    <v-snackbar v-model="snackbar" :color="snackbar_color" rounded="pill" top>
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" icon v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-window-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
//import * as auth from '../../services/auth_service';
import Authentication from "../../api/Authentication";
import { mapActions } from "vuex";
export default {
  name: "registration",
  components: {
    //
  },

  data() {
    return {
      valid: true,
      loading: false,
      snackbar: false,
      snackbar_color: "",
      text: "",
      ShowPassword: false,
      ShowConfirmPassword: false,
      error_message: "",
      error_email: "",
      error_contact: "",
      user: {
        name: "",
        email: "",
        password: "",
        password_confirmation: "",
        role_id: "",
      },
      role_id: [
        { title: "Owner", id: 2 },
        { title: "Builder", id: 3 },
        { title: "Broker", id: 4 },
      ],
      errors: {},
      titleRules: [(v) => !!v || "name is required"],

      nameRules: [(v) => !!v || "name is required"],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+/.test(v) || "E-mail must be valid.",
      ],

      contactRules: [
        (v) => !!v || "Contact Number is required",
        (v) => !isNaN(parseInt(v)) || "Contact Number must be digit.",
        (v) =>
          (v && v.length >= 10) || "Contact Number must have atleast 10 digit",
        (v) =>
          (v && v.length <= 10) ||
          "Contact Number exceeds maximum allowed number",
      ],

      passwordRules: [
        (v) => !!v || "Password is required",
        (v) => v.length >= 8 || "password at least 8 characters.",
      ],

      confirmpasswordRules: [
        (v) => !!v || "Confirm Password is required",
        (v) => v.length >= 8 || "Confirm password at least 8 characters.",
        (v) => v === this.user.password ||"Password dose not match",
      ],
    };
  },
  computed: {
    /* ...mapState({
                          error_message_email: state => state.authenticate.error_message_email,
                          error_message_contact: state => state.authenticate.error_message_contact,
                          error_message: state => state.authenticate.error_message,
    })*/
  },
  methods: {
    ...mapActions(["registerUser"]),

    validate() {
      this.$refs.form.validate();
    },
    /*
  
  register:async function(){
     try {
       //await auth.register(this.user)
       this.flashMessage.success({         
        message: 'User created successfully.'
          });
     } catch (error) {
       switch(error.response.status)
        {
          case 422:
            this.errors= error.respose.data.errors;
            break;                    
            case 500:
              this.falshMessage.error({
                message: error.response.data.message,
                time:5000
              });
              break;
            default:
              this.falshMessage.error({
                message: 'Some error occured, please try again!',
                time:5000
              });
              break;
        }
     }
   }*/

    /* async register(){

      try {
       await this.registerUser(this.user)
       //console.log(this.user)
        
      } catch (error) {
        console.log(error)
      }
   }*/
    register: function () {
      this.loading = true;
      Authentication.register(this.user)
        .then((response) => {
          console.log("response data :", response.data);
          console.log("response status :", response.status);
          console.log("response status text :", response.data.message);
          if (response.status == 201) {
            this.loading = false;
            this.$store.commit("SET_MESSAGE", response.data.message);
            window.location.replace("/auth/login");
          }

          // commit('SET_TOKEN',response.data.access_token)
        })
        .catch((err) => {
          if (err.response) {
            console.log("error data :", err.response.data);
            this.error_message = err.response.data.errors;
            this.error_email = err.response.data.errors.email;
            this.error_contact = err.response.data.errors.contact;
            //console.log('error status :',error.response.status);
          }
          this.loading = false;
          this.snackbar_color = "error";
          this.text = err.response.message;
          this.snackbar = true;
        });
    },
  },
};
</script>