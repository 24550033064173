<template>
  <div class="userhome">

    <v-container>
    <v-layout>
      <v-row justify="center">
          <ShowUserProperty v-for="userproperty in userProperty" :key="userproperty.id" :userProperty="userproperty"/>
      </v-row>
    </v-layout>
  </v-container> 
  </div>
</template>

<script>
// @ is an alias to /src
import {mapState,mapActions} from 'vuex';
import ShowUserProperty from '@/components/ShowUserProperty.vue'
export default {
  name: 'UserHome',
  components: {
   ShowUserProperty,
  },
   computed:{
            ...mapState({
                          userProperty: state => state.property.userProperty,
            })
    },
    created(){
                  this.showUserProperty();
    },
    methods:{
            ...mapActions([
                            'showUserProperty'])
    }
}
</script>
