<template>
<v-container>
  <v-stepper v-model="e1" >
    <v-stepper-header>
     
      <v-stepper-step       
        :complete="e1 > 1"         
        step="1">
       Basic Info
      </v-stepper-step>
  <v-divider></v-divider>

      <v-stepper-step
        :complete="e1 > 2"
        step="2">
        Address Details
      </v-stepper-step>

  <v-divider></v-divider>

      <v-stepper-step
        :complete="e1 > 3"
        step="3">
        Property Details      
      </v-stepper-step>

  <v-divider></v-divider>
       <v-stepper-step
        :complete="e1 > 4"
        step="4">
       Photos    
      </v-stepper-step>

  <v-divider></v-divider>
     
       <v-stepper-step
        :complete="e1 > 5"
        step="5">
       Amenities    
      </v-stepper-step>
  <v-divider></v-divider>
</v-stepper-header>
    <v-snackbar
                      v-model="snackbar"
                      :color="snackbar_color"                      
                      rounded="pill"
                      top
                    >
                    {{ text }}

                    <template v-slot:action="{ attrs }">
                      <v-btn
                        color="white"
                        icon
                        v-bind="attrs"
                        @click="snackbar = false"
                      >
                      <v-icon>mdi-window-close</v-icon>
                      </v-btn>
                    </template>
                  </v-snackbar>
<v-form
  @submit.prevent="postProperty" 
  ref="form"
  enctype="multipart/form-data"
  v-model="valid"
  lazy-validation>  
  <v-stepper-items>     
    <v-stepper-content step="1">
      
      <div>
        <div class="mb-5 pills" >          
          <div class="pills-group">
            
            <div class="font-weight-bold info--text">property For : </div>
          <span>
            <input type='radio' name='property_for' value='Sale' id='sale' v-model="addProperty.pfor" @click="resetRentPG" required>
            <label for='sale'>Sale</label>
          </span>
            <span>
            <input type='radio' name='property_for' value='Rent' id='rent_lease' v-model="addProperty.pfor" @click="resetSalePG">
            <label for='rent_lease'>Rent</label>
          </span>
          <!-- <span>
            <input type='radio' name='property_for' value='PG' id='pg' v-model="addProperty.pfor" @click="resetRentSale">
            <label for='pg'>PG</label>
          </span> -->
          
        </div>
        </div>
        <v-divider/>
     <div class="mt-5 pills" v-if="addProperty.pfor === 'Rent'">                
        <v-col
      cols="12"
      sm="6"
      md="4"
    >
      <v-menu
        v-model="datepicker"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        :rules="PickerRules"
        min-width="auto"
      >
     
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="addProperty.picker"
            label="Available From"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
              :rules="PickerRules" 
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="addProperty.picker"
          @input="datepicker = false"
        ></v-date-picker>
      </v-menu>
    </v-col>
      </div>
      <div class="mt-5 pills" v-if="addProperty.pfor ==='PG'">
                    
                <div class="mb-5 pills-group"    :rules="pgforRules">
                  <div class="font-weight-bold info--text">Accomodation For :</div>
                  <span>
                    <input type='radio' name='type' value='Male' id='male' v-model="addProperty.pgfor">
                      <label for='male'>Male</label>
                  </span>
                    
                  <span>
                    <input type='radio' name='type' value='Female' id='female' v-model="addProperty.pgfor">
                    <label for='female'>Female</label>
                  </span>
                </div>

                 <div class="mb-5 pills-group"    :rules="occupencyRules">
                  <div class="font-weight-bold info--text">Occupency:</div>
                  <span>
                    <input type='radio' name='occupency' value='single' id='single' v-model="addProperty.occupency">
                      <label for='single'>single</label>
                  </span>
                    
                  <span>
                    <input type='radio' name='occupency' value='sharing' id='sharing' v-model="addProperty.occupency">
                    <label for='sharing'>Sharing</label>
                  </span>
                </div>
         
      </div> 

      <div class="mt-5 pills " v-if="addProperty.pfor === 'Sale'">
           
             <div class="mb-5 pills-group" :rules="ptypeRules">
                 <div class="font-weight-bold info--text">property type : </div>
          <span>
            <input type='radio' name='type' value='new property' id='new' v-model="addProperty.ptype" @click="resetResaleProperty">
              <label for='new'>New Property</label>
          </span>
            
          <span>
            <input type='radio' name='type' value='resale property' id='resale' v-model="addProperty.ptype" @click="resetNewProperty">
            <label for='resale'>Resale Property</label>
          </span>
          </div>
            <v-divider/>
              <div class="mt-5 pills" v-if="addProperty.ptype === 'new property'">

                
                <div class="mb-5 pills-group" :rules="possessionRules">
                  <div class="font-weight-bold info--text">Possession :</div>
              <span>
                 <input type='radio' name='possession' value='Under Construction' id='underconstruction' v-model="addProperty.possession" @click="resetReadytoUse">
                  <label for='underconstruction'>Under Construction</label>
              </span>
               
              <span>
                <input type='radio' name='possession' value='Ready to move' id='readytomove' v-model="addProperty.possession" @click="resetUnderConstruction">
                <label for='readytomove'>Ready to move</label>
              </span>
                </div>
                  <v-divider/>
               <div class="mt-5 pills-group" v-if="addProperty.possession === 'Under Construction'">                                               
               <div>
                  <v-select
                      :items="ptime"                      
                      v-model="addProperty.p_time"
                      label="Possession"
                      value=""
                      :rules="ptimeRules"
                  />
                </div>                                           
              </div>             
            </div>
             <div class="mt-5 pills-group " 
              v-if="addProperty.possession === 'Ready to move' || 
                    addProperty.ptype === 'resale property'">
            
                <div>
                  <v-select
                   v-model="addProperty.P_Age"
                    :items="age"
                    value=""
                    label="Property Age"
                    :rules="P_AgeRules"
                  />
                </div>
              </div>             
      </div> 
     
    </div>

        <v-select
          v-model="addProperty.property"    
          :items="propertylist"
          item-text="property_name"
          item-value="id"
          label="Select Property"
          :rules="propertyRules"
                                           
          />                                                              
        <v-btn              
          color="primary"          
          @click="e1 = 2"                   
         class="float-right mt-5"
         >
          Next
        </v-btn>
        
      </v-stepper-content>

      <v-stepper-content step="2" >
        <v-row> 
        <v-col cols="12" md="6" lg="6" xl="6">    
           <v-textarea  
            :rules="addressRules"                               
            label="Enter your Address"                                                                 
            class="mb-4"
            v-model="addProperty.address"/>
        </v-col>
        <v-col cols="12" md="6" lg="6" xl="6">    
          <v-text-field
          v-model="addProperty.city"
          :rules="cityRules"                                
          label="Enter your City"                                                                 
          class="mb-4"/>

          <v-select
          :items="states"
          item-text="state_name"
          item-value="id"
          :rules="stateRules"
          label="Select State"
          v-model="addProperty.state"
          
          />
                                 
          <v-select
          v-if="addProperty.state!= null"
          :items="cities"
          v-model="addProperty.district"
          item-text="city_name"
          item-value="id"
          label="Select District"
          :rules="districtRules"
          @click="getCityList"
          />
        </v-col >        
        </v-row>
        <v-btn
          color="primary"
              
          @click=" e1 = 1"
          class="float-left mt-5"
        >
          Previous
        </v-btn>
        
        <v-btn      
         
          color="primary"
          @click="e1 = 3"                   
         class="float-right mt-5"
         >
          Next
        </v-btn>        
      </v-stepper-content>

      <v-stepper-content step="3" >
        <div class="titlebar">
          <div class="font-weight-bold white--text">{{addProperty.property.property_name}} </div>        
        </div>
      
    <v-row>
      <v-col cols="12" md="6" lg="6" xl="6">
            
          <v-text-field
          v-if="addProperty.property == 1 || 
                addProperty.property == 6 ||
                addProperty.property == 9 || 
                addProperty.property == 10 ||
                addProperty.property == 11 || 
                addProperty.property == 12"           
            v-model="addProperty.sbuiltup"
            :rules="sbuiltupRules"
            label="Super Builtup Area (in Sq.ft)"             
            type="text"            
            class=""/>

          <v-text-field 
          v-if="addProperty.property == 7 || 
                addProperty.property == 8" 
            v-model="addProperty.harea"
            :rules="hareaRules"
            label="Area In Hectre"             
            type="text"            
            class=""/>

          <v-text-field 
          v-if="addProperty.property == 1 ||
                addProperty.property == 2 || 
                addProperty.property == 3 || 
                addProperty.property == 4 ||
                addProperty.property == 5 ||
                addProperty.property == 6 ||
                addProperty.property == 8 ||
                addProperty.property == 9 || 
                addProperty.property == 10 ||
                addProperty.property == 11 || 
                addProperty.property == 12 ||
                addProperty.property == 14 ||            
                addProperty.property == 16"                
            v-model="addProperty.builtup"
            :rules="builtupRules"
            label="Builtup Area (in Sq.ft)"             
            type="text"            
            class=""/>

          <v-text-field
          v-if="addProperty.property == 1 ||
                addProperty.property == 2 || 
                addProperty.property == 3 || 
                addProperty.property == 4 ||
                addProperty.property == 5 ||
                addProperty.property == 6 ||
                addProperty.property == 8 ||
                addProperty.property == 9 || 
                addProperty.property == 10 ||
                addProperty.property == 11 || 
                addProperty.property == 12 ||
                addProperty.property == 14 ||            
                addProperty.property == 16"                    
            v-model="addProperty.carpet"
            :rules="carpetRules"
            label="Carpet Area (in Sq.ft)"             
            type="text"            
            class=""/>
            
            <v-text-field
         v-if="addProperty.property == 2 ||            
               addProperty.property == 3 || 
               addProperty.property == 4 ||
               addProperty.property == 5 ||               
               addProperty.property == 13 ||  
               addProperty.property == 14 ||
               addProperty.property == 15 ||              
               addProperty.property == 16 ||
               addProperty.property == 17"
            v-model="addProperty.plotarea"
            :rules="plotRules"
            label="Plot Area (in Sq.ft)"             
            type="text"            
            class=""/>

        <v-text-field 
        v-if="addProperty.property == 2 ||             
              addProperty.property == 3 || 
              addProperty.property == 4 ||
              addProperty.property == 5 ||             
              addProperty.property == 13 ||  
              addProperty.property == 14 ||
              addProperty.property == 15 ||              
              addProperty.property == 16 ||
              addProperty.property == 17"
            v-model="addProperty.pwidth"
            :rules="pwidthRules"
            label="Plot Width (in ft.)"             
            type="text"            
            class=""/>
         
     
              <v-text-field 
              v-if="addProperty.property == 2 || 
                    addProperty.property == 3 || 
                    addProperty.property == 4 ||
                    addProperty.property == 5 ||                    
                    addProperty.property == 13 ||  
                    addProperty.property == 14 ||
                    addProperty.property == 15 ||              
                    addProperty.property == 16 ||
                    addProperty.property == 17"
            v-model="addProperty.plength"
            :rules="plengthRules"
            label="Plot Length (in ft.)"             
            type="text"            
            class=""/>

            <v-select
                    v-if="addProperty.property == 1 ||
                          addProperty.property == 2 || 
                          addProperty.property == 3 || 
                          addProperty.property == 4 ||
                          addProperty.property == 5 ||
                          addProperty.property == 6 ||
                          addProperty.property == 8 ||
                          addProperty.property == 9 || 
                          addProperty.property == 10 ||
                          addProperty.property == 11 || 
                          addProperty.property == 12 ||
                          addProperty.property == 13 ||  
                          addProperty.property == 14 ||
                          addProperty.property == 15 ||              
                          addProperty.property == 16 ||
                          addProperty.property == 17"
                      v-model="addProperty.facing"
                      :items="facinglist"
                      label="Facing"
                      :rules="facingRules"
                    />
      </v-col>
      <v-col cols="12" md="6" lg="6" xl="6">   
          <v-select
          v-if="addProperty.property == 1 ||
                addProperty.property == 2 || 
                addProperty.property == 3 || 
                addProperty.property == 4 ||
                addProperty.property == 5 ||
                addProperty.property == 6 ||
                addProperty.property == 8 ||
                addProperty.property == 9 || 
                addProperty.property == 10 ||
                addProperty.property == 11 || 
                addProperty.property == 12" 
            v-model="addProperty.totalfloor"            
            :rules="totalfloorRules"
            label="Total No. of Floor"             
            :items="items"                     
            class=""/>          
               

        <v-select 
          v-if="addProperty.property == 1 ||                
                addProperty.property == 6 ||             
                addProperty.property == 9 || 
                addProperty.property == 10 ||
                addProperty.property == 11 || 
                addProperty.property == 12"               
            v-model="addProperty.floorno"
            :rules="floorRules"
            label="Floor No."             
            :items="items"                       
            class=""/>
            
        <v-select
          v-if="addProperty.property == 1 ||
                addProperty.property == 2 || 
                addProperty.property == 3 || 
                addProperty.property == 4 ||
                addProperty.property == 5 ||
                addProperty.property == 6 ||
                addProperty.property == 8"
               
            v-model="addProperty.bedroom"
            :items="items"
            :rules="bedroomRules"
            label="No. of Bedroom"
             />

        <v-select
        v-if="addProperty.property == 1 ||
              addProperty.property == 2 || 
              addProperty.property == 3 || 
              addProperty.property == 4 ||
              addProperty.property == 5 ||
              addProperty.property == 6 ||
              addProperty.property == 8"
          v-model="addProperty.bathroom"
          :items="items"
          label="No. of Bathroom"
          :rules="bathroomRules"
        />

        <v-select
        v-if="addProperty.property == 1 ||
              addProperty.property == 2 || 
              addProperty.property == 3 || 
              addProperty.property == 4 ||
              addProperty.property == 5 ||
              addProperty.property == 6 ||
              addProperty.property == 8"
          v-model="addProperty.balcony"
          :items="balconylist"
          label="No. Of Balcony"
          :rules="balconyRules"
        />            
        <v-text-field 
        v-if="addProperty.property == 2 || 
                    addProperty.property == 3 || 
                    addProperty.property == 4 ||
                    addProperty.property == 5 ||                    
                    addProperty.property == 13 ||                  
                    addProperty.property == 15 ||                                 
                    addProperty.property == 17"
            v-model="addProperty.roadwidth"
            :rules="roadwidthRules"
            label="Road width in front of plot(in ft.)"             
            type="text"            
            class=""/>       
            
        <v-select
        v-if="addProperty.property == 2 || 
                    addProperty.property == 3 || 
                    addProperty.property == 4 ||
                    addProperty.property == 5 ||                    
                    addProperty.property == 13 ||  
                    addProperty.property == 14 ||
                    addProperty.property == 15 ||              
                    addProperty.property == 16 ||
                    addProperty.property == 17"
          :items="sides"
          label="No. of open sides"
          :rules="opensideRules"
          v-model="addProperty.open_side"
        />
        <div class="pills-group" 
        v-if="addProperty.property == 13 ||  
              addProperty.property == 15 ||                                 
              addProperty.property == 17">
            <div class="font-weight-bold info--text">Compound on Plot : </div>
          <span>
            <input type='radio' name='compound' value=1 id='compound-yes' v-model="addProperty.compound">
            <label for='compound-yes'>Yes</label>
          </span>
            <span>
            <input type='radio' name='compound' value=0 id='compound-no' v-model="addProperty.compound">
            <label for='compound-no'>No</label>
          </span>                  
        </div>
        <v-select
        v-if="addProperty.property == 9 || 
                    addProperty.property == 10 || 
                    addProperty.property == 11 ||
                    addProperty.property == 12"
            :items="items"
            :rules="washroomRules"
                label="No. of Washroom"
                v-model="addProperty.washroom"
        />
         <div class="mb-3 pills-group"
          v-if="addProperty.property == 9 || 
                addProperty.property == 10 || 
                addProperty.property == 11 ||
                addProperty.property == 12">
            <div class="font-weight-bold info--text" :rules="cafeteriaRules">Pantry or Cafeteria : </div>
          <span>
            <input type='radio' name='pantry' value=1 id='pantry-yes' v-model="addProperty.cafeteria">
            <label for='pantry-yes'>Yes</label>
          </span>
            <span>
            <input type='radio' name='pantry' value=0 id='pantry-no' v-model="addProperty.cafeteria">
            <label for='pantry-no'>No</label>
          </span>                  
        </div>

        <div class="mb-3 pills-group"
         v-if="addProperty.property == 11 ||
               addProperty.property == 12">
            <div class="font-weight-bold info--text"  :rules="cornerPropertyRules">Corner Property: </div>
          <span>
            <input type='radio' name='corner_property' value=1 id='corner-yes' v-model="addProperty.corner_property">
            <label for='corner-yes'>Yes</label>
          </span>
            <span>
            <input type='radio' name='corner_property' value=0 id='corner-no' v-model="addProperty.corner_property">
            <label for='corner-no'>No</label>
          </span>                  
        </div>

        <div class="mb-3 pills-group"
         v-if="addProperty.property == 11 ||
               addProperty.property == 12">
            <div class="font-weight-bold info--text"  :rules="mainRoadFacingRules">Main Road Facing: </div>
          <span>
            <input type='radio' name='road_facing' value=1 id='mainroad-yes' v-model="addProperty.road_facing">
            <label for='mainroad-yes'>Yes</label>
          </span>
            <span>
            <input type='radio' name='road_facing' value=0 id='mainroad-no' v-model="addProperty.road_facing">
            <label for='mainroad-no'>No</label>
          </span>                  
        </div>        
      </v-col>
    </v-row>
        <div>
          <v-text-field 
            v-model="addProperty.price"
            :rules="priceRules"
            label="Amount in Rupees "            
            type="text"            
            class="mb-4"/>            
        <div class="pills-group">
            <div class="font-weight-bold info--text">price Negotiable : </div>
          <span>
            <input type='radio' name='price_negotiation' value=1 id='price-yes' v-model="addProperty.negotiable">
            <label for='price-yes'>Yes</label>
          </span>
            <span>
            <input type='radio' name='price_negotiation' value=0 id='price-no' v-model="addProperty.negotiable">
            <label for='price-no'>No</label>
          </span>         
         
        </div>
            <v-textarea 
            v-model="addProperty.description"
            :rules="descriptionRules"
            label="Description"             
            type="text"            
            class="mb-4"/>
        </div>
        <v-btn
          color="primary"
          @click="e1 = 2"
          class="float-left mt-5"
        >
          Previous
        </v-btn>
        <v-btn
       
          color="primary"
          @click="e1 = 4"
          class="float-right mt-5"
        >
          Next
        </v-btn>       
      </v-stepper-content>

      <v-stepper-content step="4">
      <div>
          <!-- <img :src="propPicture" width="150" class="img-preview"/>
            <v-file-input
              type="file"
              :rules="fileRules"                
              show-size                
              accept="image/png, image/jpeg, image/bmp"    
              prepend-icon="mdi-upload"
              label="upload Property Image"
              @change="uploadImage"              
            />  -->
             <v-row>
                <div v-for="(image, key) in images" :key="image.id">
                <v-col cols="12" md="3" lg="3" xl="3">
                  <div class="preview-img">
                    <img v-bind:ref="'image' +parseInt( key )" width="150px" />
                    <v-btn color="grey" icon @click="removeImage(image, key)" class="remove-image">
                      <v-icon>
                        mdi-close-circle
                      </v-icon>
                    </v-btn>
                  </div>
                </v-col>
                </div>
              </v-row>
              <v-row><span class="info--text mt-5"> You can upload multiple images(upto 10 imeges) at a time. </span> </v-row>            
              <v-file-input type="file" :rules="fileRules" :error-messages="errmsg" show-size multiple accept="image/png,image/jpeg"
                prepend-icon="mdi-camera" label="Upload property image" @change="uploadImage" />
        </div>           
        <v-btn
         color="primary"
        @click="e1 = 3"
        class="float-left mt-5">
          PREVIOUS
        </v-btn>
       <v-btn
       
          color="primary"
          @click="e1 = 5"
          class="float-right mt-5"
        >
          Next
        </v-btn>
      
      </v-stepper-content>
     
      <v-stepper-content step="5">
        <div > 
        
          <v-row
           v-if="addProperty.property == 1 || 
                addProperty.property == 6 ||
                addProperty.property == 9 ||
                addProperty.property == 10 ||
                addProperty.property == 11 ||
                addProperty.property == 12">                     
          <v-col cols="12" md="4" lg="4" xl="4">
                   <v-select
                       v-model="addProperty.maintenance" 
                      :items="maintenanceamenities"
                      item-text="amenity_name"
                      item-value="id"
                      label="Select Maintenence"                      
                       :rules="maintenanceRules"                                    
                  />               
          </v-col>
          </v-row>                            
               <v-row 
               v-if="addProperty.property == 1 ||
                            addProperty.property == 2 ||
                            addProperty.property == 3 ||
                            addProperty.property == 4 ||
                            addProperty.property == 5 ||
                            addProperty.property == 6"> 
                <v-col 
                v-for="wateramenity in  wateramenities"  :key="wateramenity.id">
                    <v-checkbox :value="wateramenity.id"                            
                            :label="wateramenity.amenity_name"
                            v-model="addProperty.amenity">
                </v-checkbox>     
                </v-col>
               </v-row>
               <v-divider></v-divider>              
               <v-row v-if="addProperty.property == 1 ||
                            addProperty.property == 2 ||
                            addProperty.property == 3 ||
                            addProperty.property == 4 ||
                            addProperty.property == 5 ||
                            addProperty.property == 6">                  
                <v-col v-for="kitchenamenity in kitchenamenities"  :key="kitchenamenity.id">
                    <v-checkbox :value="kitchenamenity.id"                            
                            :label="kitchenamenity.amenity_name"
                            v-model="addProperty.amenity">
                </v-checkbox>     
                </v-col>
               </v-row> 
                <v-divider></v-divider>
               <v-row v-if="addProperty.property == 1 ||
                            addProperty.property == 2 ||
                            addProperty.property == 3 ||
                            addProperty.property == 4 ||
                            addProperty.property == 5 ||
                            addProperty.property == 6"> 
                <v-col v-for="flooringamenity in  flooringamenities"  :key="flooringamenity.id">
                    <v-checkbox :value="flooringamenity.id"                            
                            :label="flooringamenity.amenity_name"
                            v-model="addProperty.amenity">
                </v-checkbox>     
                </v-col>
               </v-row>
               <v-divider></v-divider>
               <v-row v-if="addProperty.property == 1 ||
                            addProperty.property == 2 ||
                            addProperty.property == 3 ||
                            addProperty.property == 4 ||
                            addProperty.property == 5 ||
                            addProperty.property == 6"> 
                <v-col v-for="popamenity in  popamenities"  :key="popamenity.id">
                    <v-checkbox :value="popamenity.id"                            
                            :label="popamenity.amenity_name"
                            v-model="addProperty.amenity">
                </v-checkbox>     
                </v-col>
               </v-row>
               <v-divider></v-divider>
                <v-row v-if="addProperty.property == 1 ||                           
                             addProperty.property == 6"> 
                <v-col  v-for="parkingamenity in  parkingamenities"  :key="parkingamenity.id">
                    <v-checkbox :value="parkingamenity.id"                            
                            :label="parkingamenity.amenity_name"
                            v-model="addProperty.amenity">
                </v-checkbox>     
                </v-col>
               </v-row>
               <v-divider></v-divider>
               <v-row v-if="addProperty.property == 1 ||
                            addProperty.property == 2 ||
                            addProperty.property == 3 ||
                            addProperty.property == 4 ||
                            addProperty.property == 5 ||
                            addProperty.property == 6"> 
                <v-col v-for="furnishedamenity in  furnishedamenities"  :key="furnishedamenity.id">
                    <v-checkbox :value="furnishedamenity.id"                            
                            :label="furnishedamenity.amenity_name"
                            v-model="addProperty.amenity">
                </v-checkbox>     
                </v-col>
               </v-row>
               <v-divider></v-divider>
              
              
               <v-row v-if="addProperty.property == 1 ||
                            addProperty.property == 2 ||
                            addProperty.property == 3 ||
                            addProperty.property == 4 ||
                            addProperty.property == 5 ||
                            addProperty.property == 6"> 
                <v-col  v-for="safetyamenity in  safetyamenities"  :key="safetyamenity.id">
                    <v-checkbox :value="safetyamenity.id"                            
                            :label="safetyamenity.amenity_name"
                            v-model="addProperty.amenity">
                </v-checkbox>     
                </v-col>
               </v-row>
               <v-divider></v-divider>
               <v-row v-if="addProperty.property == 1 ||
                            addProperty.property == 2 ||
                            addProperty.property == 3 ||
                            addProperty.property == 4 ||
                            addProperty.property == 5 ||
                            addProperty.property == 6">                             
               <v-col v-for="basicamenity in  basicamenities" :key="basicamenity.id">
                  <v-checkbox 
                            :value="basicamenity.id"                             
                            :label="basicamenity.amenity_name"
                            v-model="addProperty.amenity">
                  </v-checkbox>
               </v-col>
               </v-row> 
               <v-divider></v-divider>              
                <v-row v-if="addProperty.property == 1 ||
                            addProperty.property == 2 ||
                            addProperty.property == 3 ||
                            addProperty.property == 4 ||
                            addProperty.property == 5 ||
                            addProperty.property == 6"> 
                <v-col v-for="otheramenity in  otheramenities"  :key="otheramenity.id">
                    <v-checkbox :value="otheramenity.id"                            
                            :label="otheramenity.amenity_name"
                            v-model="addProperty.amenity">
                </v-checkbox>     
                </v-col>
               </v-row>
                 
                            
        </div>
        <v-btn
         color="primary"
        @click="e1 = 4"
        class="float-left mt-5">
          PREVIOUS
        </v-btn>
        <v-btn
          type="submit"
          color="primary"
          :disabled="!valid"
          class="float-right mt-5"
          @click="validate"
        >
          SAVE
        </v-btn>

        <v-btn
        @click="e1 = 1, clearAllField"
        class="float-right mt-5">
          Cancel
        </v-btn>
      </v-stepper-content>
     
    </v-stepper-items>
   </v-form> 
  </v-stepper> 
   
</v-container>
</template>
<script>

import {mapState,mapActions} from 'vuex';
import AddPropertyMixin from '../../mixins/AddPropertyMixin';
import Property from '../../api/Property'
//import PhotoUpload from '../../components/PhotoUpload.vue'
export default {
  name : 'AddProperty',
   mixins:[AddPropertyMixin],
  components: {
   //PhotoUpload,
  
  },
  data() {
    return {
      snackbar:false,
      text:'',
      snackbar_color:null,
      propPicture:'',
      userphoto:'',
      e1: 1,
      valid: true,      
      errmsg:'',                
       propertyImg:null, 
       selectedFile: '',     
      images: [],
    };
  },
 
  computed:{
            ...mapState({
                          propertylist: state => state.property.propertylist,
                          states: state => state.city.states,
                          cities: state => state.city.cities,
                          basicamenities:state => state.amenity.basicamenities,
                          safetyamenities:state => state.amenity.safetyamenities,
                          parkingamenities:state => state.amenity.parkingamenities,
                          wateramenities:state => state.amenity.wateramenities,
                          flooringamenities:state => state.amenity.flooringamenities,
                          popamenities:state => state.amenity.popamenities,
                          kitchenamenities:state => state.amenity.kitchenamenities,
                          furnishedamenities:state => state.amenity.furnishedamenities,
                          maintenanceamenities:state => state.amenity.maintenanceamenities,
                          otheramenities:state => state.amenity.otheramenities,
                        })
            },

  mounted() {           
              this.getProperty();
              this.getState();
              this.otherAmenity();
              this.safetyAmenity();
              this.parkingAmenity();
              this.waterAmenity();
              this.flooringAmenity();
              this.kitchenAmenity();
              this.basicAmenity();
              this.popAmenity();
              this.furnishedAmenity();
              this.maintenanceAmenity();             
              },
  methods:{
            ...mapActions([
                          'getProperty',
                          'getState',
                          'getCity',
                          'otherAmenity',
                          'safetyAmenity',
                          'parkingAmenity',
                          'waterAmenity',
                          'flooringAmenity',
                          'kitchenAmenity',
                          'basicAmenity',
                          'popAmenity',
                          'furnishedAmenity',
                          'maintenanceAmenity',
                          'postProperty',
                          ]),
                        
            validate () {
                          this.$refs.form.validate('getState');
                          //this.$refs.form.validate('getCity');
                        }, 
            getCityList(){
                   this.getCity({
                    cityId: this.addProperty.state,                    
                   });                                     
                 },
                          
          //  uploadImage(e){
                     
          //           this.propertyImage = e;                    
          //       let reader = new FileReader();              
          //           reader.onload = e => {                
          //           this.propPicture = e.target.result;                  
          //           }
          //           reader.readAsDataURL(e);
          //       },
          //       propertyPhoto(){
          //           const fd = new FormData();                                         
          //                 fd.append('propertyPhoto',this.propertyPicture);   
          //         Property.postPropertyPhoto(fd)
          //         .then(response =>{                            
          //                                   if(response.status == 201)
          //                                   {
                                            
          //                                   console.log(response.data) 
          //                                   }                  
          //           }).catch(function (error) {   
          //                       console.log('Error message :', error.message);                                    
          //                                   });                
          //       },
              imageSize(){
                const bytesToMegaBytes = bytes => bytes / (1024 ** 2);
                var totalSize = 0;
                for (let i = 0; i < this.images.length; i++) {
                      console.log("this.images[i].size: ",this.images[i].size);
                      totalSize = totalSize + this.images[i].size                                             
                    }
                    let imgSize = bytesToMegaBytes(totalSize);
                    //console.log(imgSize,"MB");
                    var roundSize = Math.round(imgSize)
                    if(roundSize > 10)
                    {
                      //console.log("roundSize :",roundSize,"MB");
                      this.errmsg =" File size should be less than 10 MB!";
                    }
              },
              uploadImage(e) {
                var selectedFiles = e;
                console.log("uploadImage(e) : ", e);
                if (e.length !== 0 ) {
                  console.log("this.images.length",this.images.length)
                  if (e.length <= 10 && this.images.length <= 10) {
                    this.errmsg = "";                   
                    for (let i = 0; i < selectedFiles.length; i++) {                                          
                      this.images.push(selectedFiles[i]);
                    }
                     this.imageSize();                
                    this.previewImg();
                  } else {
                    this.errmsg = "Upload Only 10 Images."
                  }
                }
                else {
                
                  this.images.length = [];
                  this.previewImg();
                  this.errmsg = "Please select upto 10 images."
                 
                }
              },
              removeImage(image, index) {
                this.images.splice(index, 1);
                //console.log(this.images);
                 this.imageSize(); 
                this.previewImg();

              },
              previewImg() {
                for (let i = 0; i < this.images.length; i++) {
                  let reader = new FileReader(); //instantiate a new file reader                  
                  reader.addEventListener('load', function () {
                    console.log("reader.result : ",reader.result)
                    this.$refs['image' + parseInt(i)][0].src = reader.result;
                  }.bind(this), false);  //add event listener                       
                  reader.readAsDataURL(this.images[i]);
                }
              },
              async postProperty()
                 { const fd = new FormData(); 
                          fd.append('pfor',this.addProperty.pfor);
                          fd.append('ptype',this.addProperty.ptype);
                          fd.append('possession',this.addProperty.possession);
                          fd.append('pgfor',this.addProperty.pgfor);
                          fd.append('occupency',this.addProperty.occupency);
                          fd.append('p_time',this.addProperty.p_time);               
                          fd.append('P_Age',this.addProperty.P_Age); 
                          fd.append('picker',this.addProperty.picker);  
                          fd.append('property',this.addProperty.property);
                          fd.append('address',this.addProperty.address);
                          fd.append('city',this.addProperty.city);
                          fd.append('district',this.addProperty.district);
                          fd.append('state',this.addProperty.state);                
                          fd.append('negotiable',this.addProperty.negotiable);
                          fd.append('description',this.addProperty.description);
                          fd.append('price',this.addProperty.price);
                          fd.append('sbuiltup',this.addProperty.sbuiltup);
                          fd.append('builtup',this.addProperty.builtup);
                          fd.append('carpet',this.addProperty.carpet);
                          fd.append('harea',this.addProperty.harea);
                          fd.append('plotarea',this.addProperty.plotarea);
                          fd.append('totalfloor',this.addProperty.totalfloor);
                          fd.append('floorno',this.addProperty.floorno);
                          fd.append('bedroom',this.addProperty.bedroom);
                          fd.append('bathroom', this.addProperty.bathroom );                             
                          fd.append('balcony',this.addProperty.balcony);
                          fd.append('facing',this.addProperty.facing);
                          fd.append('pwidth',this.addProperty.pwidth);
                          fd.append('plength',this.addProperty.plength);
                          fd.append('roadwidth',this.addProperty.roadwidth);
                          fd.append('open_side',this.addProperty.open_side);
                          fd.append('compound',this.addProperty.compound);
                          fd.append('cafeteria',this.addProperty.cafeteria);                          
                          fd.append('washroom',this.addProperty.washroom);
                          fd.append('road_facing',this.addProperty.road_facing);
                          fd.append('corner_property',this.addProperty.corner_property);
                          //fd.append('propertyImg',this.addProperty.propertyImg);                                                        
                          fd.append('maintenance',this.addProperty.maintenance);                                                                                                        
                          //fd.append('propertyPhoto',this.propertyImage)
                          fd.append('amenity',this.addProperty.amenity);
                          for( let i = 0; i<this.images.length; i++)
                              {
                                fd.append('propertyPhoto['+i+']', this.images[i]);
                              }
                                                   
                  if(this.images.length > 0 && this.images.length <=10)
                    {
                      await Property.postProperty(fd)
                        .then(response => {
                          if (response.status == 201) {
                            this.text = response.data.message;
                            this.snackbar = true;
                            this.snackbar_color = 'success'
                            //console.log(response.data)
                            this.clearAllField();
                          }
                        }).catch(function (error) {
                          console.log('Error message :', error.message);
                        });
                    }
                    else{
                      if (this.images.length == 0)
                      {
                        this.text = "please select Images";
                            this.snackbar = true;
                            this.snackbar_color = 'danger'
                        
                      }
                      if (this.images.length > 10)
                      {                       
                        this.text = "Please upload upto 10 images";
                            this.snackbar = true;
                            this.snackbar_color = 'danger'
                      }
                    } 
                }
                
        }, 
};
</script>

<style>
	input[type='radio'] 
		{ 
			display: none; 
		}
		.pills-group
		{ 
			display: flex; 
			flex-direction: row; 
			width: 100%;
    }
		.pills-group > span 
				{
				margin-left: 10px;
        display: block;
        text-align: center;
				}
	.pills-group > span > label {
    flex: 1 1 auto;
    border: solid 1px #E0E0E0; 
    min-width:100px;
    cursor: pointer; 
    display: block; 
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
		border-radius: 50px;
		background-color: #FAFAFA;
	}
	.pills-group > span > :checked + label
    { 
    background: #1976d2 ; 
    color: #fff; 
    }

    .titlebar{
      width: 100%;
      padding: 15px 10px;
      height: 50px;
      background: #1976d2;
      border-radius: 5px 5px 0 0;
    }
    .preview-img{
     display: inline-block;
     position: relative;
     width : auto;  
    margin: 2px 1px 2px 1px
    }
    .preview-img img{
      width: 200px;
      border-radius: 5px 5px 5px 5px;
      border: solid 1.5px rgb(197, 197, 197);
      
    }
    .preview-img .remove-image{
      position: absolute;
      z-index: 1;
      top: 2px;
      right: 2px;
    }
</style>