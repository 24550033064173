
<template>
  <v-footer
    color="blue-grey lighten-5"
    padless
  >
    <v-row
      justify="center"
      no-gutters
    >
      <v-btn
        v-for="(flink,index) in footerlinks"
        :key="index"        
        color="blue-grey darken-4"
        text
        rounded
        small
        class="my-2"
        :to="flink.to"
      >
        {{ flink.title }}
      </v-btn>
     <v-col  class="py-4 text-center"
        cols="12">
       <v-btn
          v-for="(icon,index) in icons"
          :key="index"
          class="mx-4"
          :href="icon.to"
           color="blue-grey darken-4"
          icon
        >
          <v-icon size="26px">
            {{ icon.symbol }}
          </v-icon>
        </v-btn>
     </v-col>
      <v-col
        class="grey lighten-4 py-4 text-center blue-grey--text"
        cols="12"
      >
         <strong>  100 Miles Realtors © </strong> All Rights Reserved  {{ new Date().getFullYear() }}<br>
          Design & Developed By : <span ><a href="https://goo.gl/maps/CUwJ361Dd2MSKmdJ6" target="_blank" class="routerlink">Prevelance Technology</a></span>
      </v-col>
    </v-row>
  </v-footer>

  <!-- <v-footer padless class="text-center info lighten-1 footer">
     <v-row>
       
     </v-row>
      <v-row>
          <v-col
          class="text-center"
          cols="12">
                <strong class="white--text"> 100 Miles Realtors © </strong> All Rights Reserved  {{ new Date().getFullYear() }}
          </v-col>
           
          <v-col
          class="text-center"
          cols="12">
                <Strong>   Design & Developed By :</strong> <span ><a href="http://www.progtantra.co.in" target="_blank" class="white--text routerlink">Prevelance Technology</a></span>
    </v-col>
      </v-row>
  </v-footer> -->
</template>
<script>
  export default {
    data: () => ({
      footerlinks: [
        {title:"Home",to:"/"},
        {title:"About Us",to:"#", futurelinks: "/hmp/about"},
         {title:"Services",to:"#", futurelinks: "/hmp/services"},
        {title:"Privacy Policy",to:"/hmp/PrivacyPolicy"},        
        {title:"Terms And Conditions",to:"/hmp/TermsAndConditions"},               
        {title:"Contact Us",to:"/hmp/contactus"},  
        
      ],
       icons: [
         {symbol:"mdi-facebook",to:"https://www.facebook.com/100milesrealtor"},
         {symbol:"mdi-instagram",to:"https://www.instagram.com/100_miles_realtor"},
         {symbol:"mdi-whatsapp",to:"https://wa.me/919923478787"},
         {symbol:"mdi-twitter",to:"https://www.twitter.com/100milesrealtor"},
         {symbol:"mdi-youtube",to:"https://www.youtube.com/channel/UCdVotlJm9I_3V5x0r8tSABw"},
         {symbol:"mdi-telegram",to:"https://t.me/hundredmilesrealtor"},             
      ],
    }),
  }
</script>