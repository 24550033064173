<template>
 <div id="registration">
 <v-card flat width="700"  class="py-2 mx-auto">
        <v-row >
         <v-col md="5" class="justify-center align-center d-flex flex-column" >
          
             <v-img
             src="../../assets/logo.png"
              contain
              max-height="200"  
              max-width="200"                       
              transition="scale-transition"
             /> 
         
             <v-img
             src="../../assets/name.png"
              contain
              max-width="300"            
              transition="scale-transition"
             />
        
         </v-col>

         <v-divider vertical inset/>

        <v-col md="6" class="mt-3 ">
                   <v-progress-linear
                    :active="loading"
                    :indeterminate="loading"
                    absolute
                    top
                    color="blue accent-4"
                  />
                    <v-card-title class="primary--text">
                      Reset Password
                    </v-card-title>
                    <v-divider/>
                    <v-card-text>
                        <v-form
                         v-on:submit.prevent="resetPassword" 
                        ref="form"
                        v-model="valid"
                        lazy-validation>
                             <v-text-field 
                                v-model="user.email"
                                :rules="emailRules"
                                label="E-mail"
                                prepend-icon="mdi-email"
                                 class="mb-4"
                                />
                           <v-text-field 
                                v-model="user.password"
                                :rules="passwordRules"
                                label="Password" 
                                 :counter="8"
                                :type=" ShowPassword ? 'text': 'password'"
                                 prepend-icon="mdi-lock"                                 
                                 :append-icon=" ShowPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                 @click:append=" ShowPassword = !ShowPassword"
                                  class="mb-4"
                                />
                                
                                <v-text-field 
                                label="Confirm Password" 
                                v-model="user.password_confirmation"
                                :rules="confirmPasswordRules"
                                :type=" ShowConfirmPassword ? 'text': 'password'"
                                 prepend-icon="mdi-lock" 
                                 :counter="8"                                                                 
                                 :append-icon=" ShowConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                 @click:append=" ShowConfirmPassword = ! ShowConfirmPassword"
                                  class="mb-4"
                                />
                             
                            <v-btn color="info"
                                type="submit"
                                rounded
                               block
                                @click="validate"
                                :disabled="!valid">
                            Reset Password
                        </v-btn>
                        </v-form>
                    </v-card-text>
                   
                    <v-card-actions>
                                     
                      
                        
                    </v-card-actions>
                 </v-col>
      </v-row>
    </v-card>
                 <v-snackbar
                      v-model="snackbar"
                      :color="snackbar_color"                      
                      rounded="pill"
                      top
                    >
                    {{ text }}

                    <template v-slot:action="{ attrs }">
                      <v-btn
                        color="white"
                        icon
                        v-bind="attrs"
                        @click="snackbar = false"
                      >
                      <v-icon>mdi-window-close</v-icon>
                      </v-btn>
                    </template>
                  </v-snackbar>
 </div>        
</template>
<script>
import Authentication from '../../api/Authentication';
export default {
  
  data () {
     return{
              valid: true,
               user:{
                      email:'',
                      token:'',
                      password: '',
                      password_confirmation: '',
                    },
               loading:false,
                snackbar:false,
                snackbar_color:'',
                text:'',
                  ShowPassword : false,
                  ShowConfirmPassword : false,
                   
    
    emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+/.test(v) || 'E-mail must be valid.',
      ],
      
    passwordRules: [
        v => !!v || 'Password is required',
        v => v.length >= 8 || 'password at least 8 characters.',
      ],
              
      confirmPasswordRules: [
        v => !!v || 'Confirm Password is required',
        v => v.length >= 8 || 'Confirm password at least 8 characters.',
        v =>(v=== this.user.password) || 'Password dose not match',
      ],
     }
  },
   mounted() {
              this.getToken();
              
   },
 methods:{
    validate () {
        this.$refs.form.validate()
      },
      
     getToken(){
       this.user.token = this.$route.query.token;      
    },
     resetPassword: function(){
         //console.log('forgot password function executed..',this.user)
          this.loading=true;
      Authentication.resetPassword(this.user).then(response=> {
      //console.log('response data :',response);                     
      if(response.status == 200)
       {         
        this.loading=false;
        this.snackbar=true;
        this.snackbar_color='success';
        this.text=response.data.message;                 
       }
      
    }).catch(error=> {
      //console.log('Error message :', error.response.data.message);    
        this.loading=false;
        this.snackbar=true;
        this.snackbar_color='error';
        this.text= error.response.data.message;
        });
     }
  }
}
</script>
