
export default {
                basicamenities:[],
                safetyamenities:[],
                parkingamenities:[],
                wateramenities:[],
                flooringamenities:[],
                popamenities:[],
                kitchenamenities:[],
                furnishedamenities:[],
                maintenanceamenities:[],
                otheramenities:[],
            }