<template>    
  <div class="finance">
   <HomeAppBar/>
  <h1>Finance under development.</h1>
    <Footer/>
  </div>
 
</template>

<script>
// @ is an alias to /src
//import HelloWorld from '@/components/HelloWorld.vue'
import HomeAppBar from '@/components/HomeAppBar.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'Home',
  components: {
   // HelloWorld,
    HomeAppBar,
    Footer,
  }
}
</script>
