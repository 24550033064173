<template>
  <v-card flat width="750"  class="py-2 mx-auto">
        <v-row >
         <v-col md="5" class="justify-center align-center d-flex flex-column" >
          
             <v-img
             src="../../assets/logo.png"
              contain
              max-height="200"  
              max-width="200"                       
              transition="scale-transition"
             /> 
         
             <v-img
             src="../../assets/name.png"
              contain
              max-width="300"            
              transition="scale-transition"
             />
        
         </v-col>

         <v-divider vertical inset/>

        <v-col md="6" class="mt-3 ">
                    <v-progress-linear
                    :active="loading"
                    :indeterminate="loading"
                    absolute
                    top
                    color="blue accent-4"
                  />
                    <v-card-title class="primary--text">
                      Forgot Password
                    </v-card-title>
                    <v-divider/>
                    <v-card-text>
                      <p>Enter your email address for reset passowrd link.</p>
                        <v-form
                        v-on:submit.prevent="forgotpassword" 
                        ref="form"
                        v-model="valid"
                        lazy-validation>
                           <v-text-field 
                                v-model="email"
                                :rules="emailRules"
                                label="E-mail"
                                prepend-icon="mdi-email"
                               
                                />
                         <v-btn color="info"
                                  type="submit"
                                  rounded
                                   block
                                   
                                  @click="validate"
                                  :disabled="!valid">
                                   Reset Password
                                </v-btn>   
                        </v-form>
                    </v-card-text>
                    <v-divider/>
                   <v-card-actions> 
                      <div class="login-action caption"> 
                        <div>
                            already have an account?
                            <router-link to="/auth/login" class="routerlink">Sign In</router-link> . 
                        </div> 
                        <div>                      
                            Don't Have an Account ?
                            <router-link to="/auth/register" class="routerlink">Create Account</router-link>                          
                        </div>
                      </div>     
                    </v-card-actions>
                    </v-col>
        </v-row>
                     <v-snackbar
                      v-model="snackbar"
                      :color="snackbar_color"                      
                      rounded="pill"
                      top
                    >
                    {{ text }}

                    <template v-slot:action="{ attrs }">
                      <v-btn
                        color="white"
                        icon
                        v-bind="attrs"
                        @click="snackbar = false"
                      >
                      <v-icon>mdi-window-close</v-icon>
                      </v-btn>
                    </template>
                  </v-snackbar>
                 </v-card>
            
</template>
<script>
import Authentication from '../../api/Authentication';
export default {
  
  data() {
      return {     
                email:'',
                loading:false,
                snackbar:false,
                snackbar_color:'',
                text:'',
                valid:true,
    emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+/.test(v) || 'E-mail must be valid.',
      ],
     }
  },
  methods:{
      validate () {
        this.$refs.form.validate()
      },
      
     forgotpassword: function(){
         console.log('forgot password function executed..')
          this.loading=true;
      Authentication.forgotPassword(this.email).then(response=> {
      //console.log('response data :',response.data);
      this.loading=false;              
      if(response.status == 200)
       {
         this.loading= false;
         this.snackbar = true; 
        this.snackbar_color ='success';
        this.text = response.data.success;
       }      
    }).catch(error=> {
      if (error.response) {   
          this.loading= false;
         this.snackbar = true; 
        this.snackbar_color ='error'; 
        this.text = error.response.data;  
        //console.log('error Response :',error.response);               
       
      }
      else if (error.request) {
          this.loading= false;
         this.snackbar = true; 
        this.snackbar_color ='error'; 
        this.text = error.request;         
        
      } 
      else {       
         this.loading= false;
         this.snackbar = true; 
        this.snackbar_color ='error' ;
        this.text = error.message;
       
      }
      
    });
   }
  }
}
</script>
