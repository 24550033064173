<template>
  <v-slide-group
    class="pa-4"
    show-arrows
  >
    <v-slide-item v-for="service in services" :key="service.title">
      <v-card
        elevation="2"
        class="ma-3 pa-4"
        width="200"
        :color="service.color"
        shaped
      >
        <v-icon x-large :color="service.icolor">{{ service.icon }}</v-icon>
        <div class="subtitle-1 mt-2">
          <b :class="service.tcolor">{{ service.title }}</b>
        </div>
        <div class="caption text-justify">
          {{ service.text }}
        </div>
      </v-card>
    </v-slide-item>
  </v-slide-group>
</template>

<script>
export default {
  data() {
    return {
      model: 0,
      services: [
        {
          icon: "fas fa-hand-holding-usd",
          title: "Home Loan",
          text: "Our experts will help you for Loan facility and other fanacial acivity.",
          tcolor: "pink--text",
          icolor: "pink lighten-2",
          color: "pink lighten-5",
        },
        {
          icon: "fas fa-paint-roller",
          title: "Home Decor",
          text: "We are offering budget friendly packages for home decor and interior design.",
          tcolor: "green--text",
          icolor: "green lighten-2",
          color: "green lighten-5",
        },
        {
          icon: "fas fa-rupee-sign",
          title: "Property Valuation",
          text: "Our aim is to owner get right value of their property.",
          tcolor: "deep-orange--text",
          icolor: "deep-orange lighten-2",
          color: "deep-orange lighten-5",
        },
        {
          icon: "fas fa-house-damage",
          title: "Property Insurance",
          text: "Our experts will guide you choose right insurance for your property.",
          tcolor: "blue-grey--text",
          icolor: "blue-grey lighten-2",
          color: "blue-grey lighten-5",
        },
        {
          icon: "fas fa-map",
          title: "Plan Approval",
          text: "Our experts will help you for plan approval.",
          tcolor: "light-green--text",
          icolor: "light-green lighten-2",
          color: "light-green lighten-5",
        },
       /* {
          icon: "fas fa-file-contract",
          title: "Rent Agreement",
          text: "complete ",
          tcolor: "red--text",
          icolor: "red lighten-2",
          color: "red lighten-5",
        },*/
        {
          icon: "fas fa-bug",
          title: "Pest Control",
          text: "Our aim is to ensure a healthy and pest-free environment for you and your family",
          tcolor: "amber--text",
          icolor: "amber lighten-2",
          color: "amber lighten-5",
        },
        {
          icon: "fas fa-broom",
          title: "House Cleaning",
          text: "clean house is a key of healthy life. we are providing budget friendly cleaning service for your home.",
          tcolor: "cyan--text",
          icolor: "cyan lighten-2",
          color: "cyan lighten-5",
        },
      ],
    };
  },
};
</script>