<template>
  <div class="home">
    <HomeAppBar />

    <div class="home-container align-link-center">
      <v-form>
        <v-container class="search-bg">
          <SearchProperty />
        </v-container>
      </v-form>
    </div>
    <v-container fluid class="grey lighten-5">
      <v-row>
        <Services />
      </v-row>
    </v-container>

    <v-container>
      <v-row>
        <DisplayProperty
          v-for="property in allProperty"
          :key="property.id"
          :property="property"
        />
      </v-row>
      <v-row v-if="allProperty != null">
        <Pagination store="property" collection="allProperty" />
      </v-row>
    </v-container>
    <!-- <SocialMedia /> -->

    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from '@/components/HelloWorld.vue'
import HomeAppBar from "@/components/HomeAppBar.vue";
import SearchProperty from "@/components/SearchProperty.vue";
import Footer from "@/components/Footer.vue";
//import SocialMedia from "@/components/SocialMedia.vue";
import DisplayProperty from "@/components/DisplayProperty.vue";
import Pagination from "@/components/Pagination.vue";
import Services from "@/components/Services.vue";
import { mapState, mapActions } from "vuex";
export default {
  name: "Home",

  components: {
    //SocialMedia,
    HomeAppBar,
    Pagination,
    DisplayProperty,
    SearchProperty,
    Footer,
    Services,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      allProperty: (state) => state.property.allProperty.data,
    }),
  },
  created() {
    this.showProperty(0);
  },
  methods: {
    ...mapActions(["showProperty"]),
  },
};
</script>
<style >
.search-bg {
  background-image: linear-gradient(
    -90deg,
    rgba(23, 234, 217, 0.9) 0%,
    rgba(96, 120, 234, 0.8) 100%
  );
  border-radius: 5px;
}
</style>