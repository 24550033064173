<template>
  <div class="otherpages">
    <HomeAppBar />    
    


        <router-view></router-view>
   
    <!-- <SocialMedia /> -->

    <Footer />
  </div>
</template>

<script>
import HomeAppBar from "@/components/HomeAppBar.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "Home",
  components: {
    //SocialMedia,
    HomeAppBar,   
    Footer,  
  },   
};
</script>
