<template>
  <div class="auth">
    <!--<v-toolbar flat>
      <div class="d-flex align-center">
        <v-img
          alt="100 Miles Logo"
          class="mr-2 shrink"
          contain
          src="../../assets/100logo.png"
          transition="scale-transition"
          max-height="48"
          max-width="48"
        />

        <v-btn target="_blank" text>
          <span class="mr-2 blue--text">100 MILES REALTORS</span>
        </v-btn>
      </div>

      <v-spacer></v-spacer>

      <v-btn text class="ma-2">
        <router-link to="/" class="routerlink"
          ><v-icon dark large color="blue darken-2"
            >mdi-home</v-icon
          ></router-link
        >
      </v-btn>
      <v-btn outlined color="primary">
        <router-link to="/auth/login" class="routerlink">Log In</router-link>
      </v-btn>
      <v-btn class="ma-2" outlined color="primary">
        <router-link to="/auth/register" class="routerlink"
          >Register</router-link
        >
      </v-btn>
    </v-toolbar>-->
    <v-container class="container align-center d-flex">
      <v-row>
        <router-view > </router-view>
      </v-row>
    </v-container>
   
    <div class="footer"></div>
  </div>
</template>
<script>
// @ is an alias to /src
//import HomeAppBar from '@/components/HomeAppBar.vue';
//import Footer from '@/components/Footer.vue';

export default {
  components: {
    //  HomeAppBar,
    //Footer,
  },
};
</script>
<style scoped>
.container{
  height: 100vh;
  width: 100%;
}
</style>
