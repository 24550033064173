<template>
     <v-app-bar
     color="White"
      app      
    >
      <div class="d-flex align-center">
        <v-img
          alt="100 Miles Logo"
          class="mr-2 shrink"
          contain        
          src="../assets/100logo.png"
          transition="scale-transition"
          max-height="36"
          max-width="36"
        />

        <v-btn
        href="/"      
        plain
         color="blue darken-4"
      >
        <span class="mr-2">100 MILES REALTORS</span>        
      </v-btn>
      </div>

      <v-spacer></v-spacer>
    
         <!-- <v-btn          
        text
      ><router-link to="/" class="routerlink">Home</router-link></v-btn>
        <v-btn       
        text
      ><router-link to="/finance" class="routerlink">Finance</router-link></v-btn> -->
        <!-- <div text>
          <v-btn         
          class="mx-4"
          href="/hmp/download-app"
          plain
           color="blue darken-4"          
        >
          <v-icon size="24px">
            fas fa-mobile-alt
          </v-icon>
          Download App
        </v-btn>
          </div> -->
    <div text v-if="token">
       <div text v-if="roleid ==1">
          <router-link to="/admin/dashboard" class="routerlink"><v-btn color="primary" small outlined>Admin Home</v-btn></router-link>
       </div>
       <div text v-else>
         <router-link to="/user/home" class="routerlink"><v-btn color="primary" small outlined>User Home</v-btn></router-link>
       </div>
      </div>
      <div text v-else> 
          <router-link to="/auth/login" class="routerlink"><v-btn color="primary" small outlined>Post Property</v-btn></router-link>
      </div>
    </v-app-bar>
</template>
<script>
export default {
     name: 'HomeAppBar',
     data(){
       return{
          token : null,
          roleid:null,
       }
     },
   mounted() {   
     this.getToken();
   },
  methods:{
            getToken : function(){
                       this.token = localStorage.getItem('token');
                       this.roleid = localStorage.getItem('rlid'); 
                      }
  },
   
}
</script>
<style >

</style>