export default {
   propertylist:[],
   error:null,
   property_message:null,
   allProperty:{},
   allSearchProperty:[],
   property_type:[],
   basicDetails:[],
   propertyPhoto:[],
   amenityDetails:[],
   allEnquiry:[],

/* ******************* Authenticated User Property States ********************* */
   userProperty:[],
   /* ******************* Admin Property States ********************* */
   allAdminProperty:[],
}