<!-- eslint-disable no-undef -->
<template>
  <v-app>
    <v-main>
      <!-- <p>height {{inHt}}</p> -->
      <router-view />
    </v-main>
  </v-app>
</template>
<script>
export default {
  name: "App",
  data() {
    return {
      orientation: "",
      scWt: "",
      //isMobile: false,
    };
  },
  mounted() {
    this.redirectUrl();
   // window.addEventListener("resize", this.redirectUrl);
  }, 
  methods: {
    // getDevice() {
    //   const ua = navigator.userAgent;
    //   const device = /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua);
    //   //alert(device);

    //   if (screen.width < 600 || device=="true" ) {
    //      window.location.replace("https://100milesrealtors.com/m/");
    //    }
      
    //   if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    //     console.log("tablet");
    //   } else if (
    //     /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
    //       ua
    //     )
    //   ) {
    //     console.log("mobile");
    //   }else
    //   console.log("desktop");
    // },
    redirectUrl() {
      this.scWt = screen.width;
      const ua = navigator.userAgent;
      const device = /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua);
      //alert(device);
      if (screen.width < 600 || device=="true" ) {
        window.location.replace("https://100milesrealtors.com/m/");
      }
    },
  },
};
</script>
<style>
.routerlink {
  text-decoration: none;
}
.login-action {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.align-link-center {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.signup-link {
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  width: 100%;
  margin-bottom: 10px;
}
.home-container {
  min-height: 400px;
  width: 100vw;
  background: url(./assets/building.jpg) bottom center no-repeat;
}
.hmp-container {
  min-height: 300px;
  width: 100vw;
  background: url(./assets/building.jpg) bottom center no-repeat;
}
.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  justify-content: space-around;
}
.footer {
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
}
</style>