<template>
  <div>
<v-banner
 sticky
 dark
 rounded
 color="teal lighten-3"
 >
 <span class="title"> Profile Setting</span>
</v-banner>
<v-container>
  <v-row justify="center">
    <v-col cols="12" md="6" lg="6" xl="6" sm="12">       
        <v-snackbar
                      v-model="snackbar"
                      :color="snackbar_color"                      
                      rounded="pill"
                      top
                    >
                    {{ text }}                     
                    <template v-slot:action="{ attrs }">
                      <v-btn
                        color="white"
                        icon
                        v-bind="attrs"
                        @click="snackbar = false"
                      >
                      <v-icon>mdi-window-close</v-icon>
                      </v-btn>
                    </template>
                  </v-snackbar>
                
    <v-expansion-panels inset focusable>
      <v-expansion-panel>
        <v-expansion-panel-header> <b> <span class="primary--text">User Name :</span> {{userProfile.Name}}</b></v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-form
           v-on:submit.prevent="updateUserName" 
                    ref="form"
                    v-model="valid"
                    lazy-validation>
                   
            <v-text-field
            label="User Name"            
            prepend-icon="mdi-account-circle"            
            :value="userProfile.Name"
             v-model="name"
             :rules="nameRules"
            ></v-text-field>
             <v-btn color="info"
                type="submit"                                
                rounded  
                small                                             
                @click="validate"
                :disabled="!valid">
                Change Name
              </v-btn>
          </v-form>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header>  <b><span class="primary--text">Contact Number : </span>{{userProfile.Contact}}</b></v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-form
           v-on:submit.prevent="updateContactNumber" 
                    ref="form"
                    v-model="valid"
                    lazy-validation>
            <v-text-field
            label="Mobile Number"
            prepend-icon="mdi-account"
          v-model="contact"
            number
            :value="userProfile.Contact"
            :rules="contactRules"
            ></v-text-field>
            <v-btn color="info"
                                  type="submit"                                
                                  rounded 
                                  small                                              
                                  @click="validate"
                                  :disabled="!valid">
                                    Update Number
                                  </v-btn>
          </v-form>
        </v-expansion-panel-content>
      </v-expansion-panel>
    

     <v-expansion-panel>
        <v-expansion-panel-header>   <b class="primary--text"> Change Password</b> </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-form
           v-on:submit.prevent="changePassword" 
                    ref="form"
                   
                    v-model="valid"
                    lazy-validation>
            <v-text-field 
                                v-model="password.currentpassword"
                                :rules="passwordRules"
                                label="Current Password" 
                                 :counter="8"
                                :type=" ShowCurPassword ? 'text': 'password'"
                                 prepend-icon="mdi-lock"                                 
                                 :append-icon=" ShowCurPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                 @click:append=" ShowCurPassword = ! ShowCurPassword"
                                />
             <v-text-field 
                                v-model="password.password"
                                :rules="passwordRules"
                                label="New Password" 
                                 :counter="8"
                                :type=" ShowPassword ? 'text': 'password'"
                                 prepend-icon="mdi-lock"                                 
                                 :append-icon=" ShowPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                 @click:append=" ShowPassword = ! ShowPassword"
                                />
                                
                                <v-text-field 
                                label="Confirm New Password" 
                                v-model="password.password_confirmation"
                                :rule="confirmpasswordRules"
                                :type=" ShowConfirmPassword ? 'text': 'password'"
                                 prepend-icon="mdi-lock" 
                                 :counter="8"                                
                                 :append-icon=" ShowConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                 @click:append=" ShowConfirmPassword = ! ShowConfirmPassword"
                                />
                                 <v-btn color="info"
                                  type="submit"                                
                                  rounded  
                                  small                                             
                                  @click="validate"
                                  :disabled="!valid">
                                    Change Password
                                  </v-btn>
          </v-form>
        </v-expansion-panel-content>
      </v-expansion-panel>
    

     <v-expansion-panel>
        <v-expansion-panel-header>
          <v-row
              align="center"
              class="spacer"
              no-gutters
            >
              <v-col
                cols="12"                
              >
             
                <v-avatar
                  size="48px"
                >
                  <img                   
                    alt="Avatar"
                    :src="imgURL+'/'+userProfile.Id+'/avatar/'+userProfile.avatar"
                  >                 
                </v-avatar>
                <b class="primary--text"> Profile Photo</b>

               
              </v-col>
          </v-row>
    </v-expansion-panel-header>
        <v-expansion-panel-content>
            <v-form
           v-on:submit.prevent="updateProfilePhoto" 
                    ref="form"
                    enctype="multipart/form-data"
                    v-model="valid"
                    lazy-validation>
                    
          <img :src="userphoto" width="150" class="mt-4 img-preview" v-if="valid && userphoto"/>
          <v-file-input
              type="file"
              :rules="fileRules"                
              show-size                
              accept="image/png, image/jpeg, image/bmp"    
              prepend-icon="mdi-upload"
              label="upload image for Avatar"
              @change="displayImage"
              
            />
            <v-btn color="info"
                                  type="submit"                                
                                  rounded  
                                  small                                             
                                  @click="validate"
                                  :disabled="!valid">
                                    Upload Photo
                                  </v-btn>
            </v-form>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    </v-col>
  </v-row>
  
</v-container>
  </div>
</template>

<script>
import {mapState,mapActions} from 'vuex'

import Authenticate from '../api/Authentication'
export default {
  components: {
     
  },
   data() {
          return{
              valid: true,
              ShowCurPassword : false,
              ShowPassword : false,
              ShowConfirmPassword : false, 
              id:'',
              name:'',
              contact:'',             
              snackbar:false,
              text:'',
              snackbar_color:null,
               userphoto:null, 
               profilePhoto:null,
               imgURL:'https://100milesrealtors.com/routes/storage/images',
              password:{
                        currentpassword:'',     
                        password: '',
                        password_confirmation: '',
                        },
            
            nameRules: [
                    v => !!v || 'name is required',        
      ],                  
        contactRules: [
        v => !!v || 'Contact Number is required',
        v => !isNaN(parseInt(v)) || 'Contact Number must be digit.',
        //v => v.length >= 10 && v.length <= 10|| 'Contact Number must be 10 digit',        
      ],
      fileRules: [
        v => !!v || 'Please Select Image.',
       v => !v || v.size < 1000000 || 'Avatar size should be less than 1 MB!',
      ],
      passwordRules: [
        v => !!v || 'Password is required',
        v => v.length >= 8 || 'password at least 8 characters.',
      ],
      
        
      confirmpasswordRules: [
        v => !!v || 'Confirm Password is required',
        v => v.length >= 8 || 'Confirm password at least 8 characters.',
        (this.password === this.password_confirmation) || 'Password dose not match',
      ],           
          }
   },
 computed:{
             ...mapState({
                          userProfile: state => state.authenticate.userProfile,                          
                        })
  },
   mounted() {   
     this.getUser();
   },
  methods:{
            ...mapActions(['getUser',                         
            ]), 
             validate () {
        this.$refs.form.validate()
      },

      updateUserName : function(){  
       
          Authenticate.updateUserName(this.userProfile.Id,{'name':this.name},)                    
            .then(response =>  {                                      
                  if(response.data.status_code == 201)
                  {
                   this.text = response.data.message;
                   this.snackbar = true;
                   this.snackbar_color = 'success' 
                   this.getUser();                                   
                  }
                  
                }).catch(err => {
                          this.text = err.response.data.message
                          this.snackbar = true;
                          this.snackbar_color = 'error'
                          
                  
                });
      },
      changePassword: function(){    
           Authenticate.changePassword(this.userProfile.Id,this.password,)
         .then(response => {   
           console.log(response.data);                         
                   if(response.data.status_code == 201)
                  {
                   this.text = response.data.message;
                   this.snackbar = true;
                   this.snackbar_color = 'success'                                    
                  }
                  
                }).catch(err => {
                          this.text = err.response.data.errors
                          this.snackbar = true;
                          this.snackbar_color = 'error'                  
                });
      },
       updateContactNumber: function(){
     
          Authenticate.updateContactNumber(this.userProfile.Id,{'contact':this.contact},)         
         .then(response =>  {                            
                  if(response.data.status_code == 201)
                  {
                   this.text = response.data.message;
                   this.snackbar = true;
                   this.snackbar_color = 'success' 
                    this.getUser();  
                  }
                  
                }).catch(err => {
                          this.text = err.response.data.message
                          this.snackbar = true;
                          this.snackbar_color = 'error'                  
                });
      },
             displayImage(e){ 
               this.profileImage = e;
              //  console.log("displayImage : ",e)                  
              let reader = new FileReader();              
                reader.onload = e => {                
                this.userphoto = e.target.result; 
                console.log( e.target.result);                
                }
                reader.readAsDataURL(e);
              },            
            updateProfilePhoto: function(){                       
                const fd = new FormData();                   
                fd.append('avatar',this.profileImage)                         
                
                Authenticate.uploadImage(this.userProfile.Id,fd)
                .then(response => {                            
                  if(response.data.status_code == 201)
                  {
                   this.text = response.data.message;
                   this.snackbar = true;
                   this.snackbar_color = 'success' 
                    this.getUser();                                     
                  }
                  
                }).catch(err => {
                          this.text = err.response.data.message
                          this.snackbar = true;
                          this.snackbar_color = 'error'                  
                });
      }

  }
}
</script>

<style>

</style>