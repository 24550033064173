<template>

    <div class="privacy-policy">
      <v-container>
        <v-layout>
          <v-row>
            <v-col cols="12" md="8" xl="8" sm="12" >
              <v-card color="py-4 px-5">
                <h2 class="mb-2">Privacy Policy</h2>
                <v-divider />
                <div class="mt-3 text-justify text-caption">
                  <p>
                    This Privacy policy is subject to the terms of the Site
                    Policy (User agreement) of 100 Miles Realtors. This policy
                    is effective from the date and time a user registers with
                    100 Miles Realtors by filling up the Registration form and
                    accepting the terms and conditions laid out in the Site
                    Policy.
                  </p>

                  <p>
                    In order to provide a personalised browsing experience, 100
                    Miles Realtors may collect personal information from you.
                    Additionally some of our websites may require you to
                    complete a registration form or seek some information from
                    you. When you let us have your preferences, we will be able
                    to deliver or allow you to access the most relevant
                    information that meets your end.
                  </p>

                  <p>
                    To extend this personalized experience 100 Miles Realtors
                    save certain information on your system in the form of cookies. A
                    user has the option of accepting or declining the cookies of
                    this website by changing the settings of your browser.
                  </p>

                  <p>
                    The personal information provided by the users to 100 Miles
                    Realtors will not be provided to third parties without
                    previous consent of the user concerned. Information of a
                    general nature may however be revealed to external parties
                  </p>

                  <p>
                    Every effort will be made to keep the information provided
                    by users in a safe manner, the information will be displayed
                    on the website will be done so only after obtaining consent
                    from the users. Any user browsing the site generally is not
                    required to disclose his identity or provide any information
                    about him/her, it is only at the time of registration you
                    will be required to furnish the details in the registration
                    form.
                  </p>

                  <p>
                    A full user always has the option of not providing the
                    information which is not mandatory. You aresolely
                    responsible for maintaining confidentiality of the User
                    password and user identification and all activities and
                    transmission performed by the User through his user
                    identification and shall be solely responsible for carrying
                    out any online or off-line transaction involving credit
                    cards / debit cards or such other forms of instruments or
                    documents for making such transactions and IEIL assumes no
                    responsibility or liability for their improper use of
                    information relating to such usage of credit cards / debit
                    cards used by the subscriber online / off-line.
                  </p>

                  <p>
                    You agree that IEIL may use personal information about you
                    to improve its marketing and promotional efforts, to analyze
                    site usage, improve the Site's content and product
                    offerings, and customise the Site's content, layout, and
                    services. These uses improve the Site and better tailor it
                    to meet your needs, so as to provide you with a smooth,
                    efficient, safe and customised experience while using the
                    Site.
                  </p>

                  <p>
                    You agree that IEIL may use your personal information to
                    contact you and deliver information to you that, in some
                    cases, are targeted to your interests, such as targeted
                    banner advertisements, administrative notices, product
                    offerings, and communications relevant to your use of the
                    Site. By accepting the User Agreement and Privacy Policy,
                    you expressly agree to receive this information. If you do
                    not wish to receive these communications, we encourage you
                    to opt out of the receipt of certain communications in your
                    profile. You may make changes to your profile at any time.
                    It is the belief of IEIL that privacy of a person can be
                    best guaranteed by working in conjunction with the Law
                    enforcement authorities.
                  </p>

                  <p>
                    All IEIL websites including 100 Miles Realtors fully comply
                    with all Indian Laws applicable. IEIL has always cooperated
                    with all law enforcement inquires. IEIL may disclose all or
                    part of your personal details in response to a request from
                    the law enforcement authorities or in a case of bonafide
                    requirement to prevent an imminent breach of the law
                  </p>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-layout>
      </v-container>
    </div>
       
</template>

<script>

export default {
  name: "Privacy-Policy",
};
</script>
