import Vue from 'vue'
import App from './App.vue'
//import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
//import usedevice from "./plugins/useDevice";
import axios from 'axios'

require('@/store/subcriber')
axios.defaults.baseURL = 'https://www.100milesrealtors.com/routes/api/',
axios.defaults.headers.common['Authorization'] ='Bearer '+localStorage.getItem('token');
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
axios.defaults.headers.post['Content-Type'] = 'multipart/form-data';

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  //usedevice,
  render: h => h(App)
}).$mount('#app')
