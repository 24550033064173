<template>
  <div>
    <v-container>
      <v-card class="rounded-lg card-border my-1 py-2 px-2" light elevation="1">
        <router-link
          style="text-decoration: none"
          :to="{
            name: 'property',
            params: {
              title:
                property.propertytype.property_name + 'for' + property.pfor,
              id: property.basic_property_id,
              imgUrl:
                property.user_id +
                '/' +
                property.basic_property_id +
                '/' +
                property.image_name,
            },
          }"
        >
          <v-img
            :src="
              imgURL +
              '/' +
              property.user_id +
              '/' +
              property.basic_property_id +
              '/' +
              property.image_name
            "
            height="250"
            width="350"
            class="rounded-lg"
          ></v-img>
          <v-card-title class="title-color px-2">
            <span v-if="property.bedroom"> {{ property.bedroom }} BHK </span>
            &nbsp;{{ property.propertytype.property_name }} for
            {{ property.pfor }}
          </v-card-title>
        </router-link>
        <v-card-actions>
          <v-icon
            left
            small
            color="
         blue-grey accent-4"
          >
            fas fa-map-marker-alt
          </v-icon>
          {{ property.city }}
          &nbsp;
          &nbsp;
          &nbsp;
          <v-icon
            left
            small
            color="
         blue-grey accent-3"
         class="ml-4"
          >
            fas fa-rupee-sign
          </v-icon>
          {{ this.editedPrice }}
          <v-spacer></v-spacer>          
        </v-card-actions>
      </v-card>

      <!-- </v-col>
      </v-row> -->
    </v-container>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  props: ["property"],
  data(){
    return{
      imgURL: "https://100milesrealtors.com/routes/storage/images",
      show: false,
       editedPrice: "",
    }
  },
  computed: {
    // ...mapGetters(['priceTextConversion'])
  },
   mounted() {
    this.printValue();
  },
  methods: {
    ...mapActions(["viewPropertyDetails"]),
   printValue() {
      let amt = this.property.price;
      let count = amt.length;
      switch (count) {
        case 6:
          this.stringVal = amt.substr(0, 3);
          this.editedPrice =
            this.stringVal.substr(0, 1) +
            "." +
            this.stringVal.substr(1, this.stringVal.length) +
            " Lac";
          break;
        case 7:
          this.stringVal = amt.substr(0, 4);
          this.editedPrice =
            this.stringVal.substr(0, 2) +
            "." +
            this.stringVal.substr(2, this.stringVal.length) +
            " Lac";
          break;
        case 8:
          this.stringVal = amt.substr(0, 3);
          this.editedPrice =
            this.stringVal.substr(0, 1) +
            "." +
            this.stringVal.substr(1, this.stringVal.length) +
            " Crore";
          break;
        case 9:
          this.stringVal = amt.substr(0, 4);
          this.editedPrice =
            this.stringVal.substr(0, 2) +
            "." +
            this.stringVal.substr(2, this.stringVal.length) +
            " crore";
          break;
      }
    },
  },
};
</script>
<style scoped>
router-link{
text-decoration: none;
}
.title-color {
  color: #212121;
}
</style>