<template>
  <div class="updateproperty">
   
    <h3>User Dashboard under development.</h3>
  </div>
</template>

<script>
// @ is an alias to /src


export default {
 
 
}
</script>
